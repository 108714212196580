.standing-container .standing-positions {
    cursor: default;
}

.standing-container .standing-positions .standing-position {
    cursor: pointer;
    transition: background-color .2s ease-in-out;
}

.standing-container .standing-positions .standing-position:hover {
    background-color: var(--card-hover-background-color);
}

