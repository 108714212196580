body.light,
body {
    --card-box-shadow: 0px 4px 15px -6px rgba(0, 0, 0, 0.1),0px 4px 4px -4px rgba(0, 0, 0, 0.1),4px 0px 7px -7px rgba(0, 0, 0, 0.1),-4px 0px 7px -7px rgba(0, 0, 0, 0.1);
    --card-margin: 15px 10px;
    --card-padding: 15px 12px;

    --meta-theme-color: #2e93f8;

    --default-fonts: -apple-system, "Roboto", Arial, Helvetica, sans-serif;
    --title-fonts: var(--default-fonts);
    --third-font: "Roboto Condensed", Arial, Helvetica, sans-serif; 

    --body-background-color: #edf1f4;

    --primary: #2e93f8;
    --primary-lighter: #e0eeff;
    --primary-lightest: #f2f8ff;

    --secondary: #3784D1;

    --ternary: #1271d1;
    --ternary-var-1: #ffcd75;
    --ternary-lighter: #F9F871;
    --ternary-darker: #FF7149;

    --accent-color-1: #F45B69;
    --accent-color-2: #88498F;

    --save-card-pinned-background: #e8f4ff;
    --save-card-pinned-color: #33414f;

    --text: #414951;
    --text-subtle: #5f6a75;
    --text-light: #9eacb5;
    --text-lightest: #f0f0f5;
    --text-label: #97aabc;
    --text-label-darker: #52606d;

    --text-ternary: #fff2de;
    --text-ternary-lighter: #ede7dd;
    --text-ternary-darker: #ccdbec;

    --header: #2e93f8;
    --header-text: #f5f6f7;
    --header-match-details: #68d64f;

    --match-best-players: #f5f5f5;
    --match-best-players-rating-background: white;

    --match-story-line-background: #68d64f;
    --match-story-line-pause-point-background: white;
    --match-story-line-pause-point-border: #68d64f;
    --match-story-line-pin-background: #d1daed;

    --side-nav-backdrop-color: #00000080;
    --side-nav-background: #ffffff;
    --side-nav-footer-background: #ffffff;
    --side-nav-item-background: #ffffff;
    --side-nav-item-hover: #ebebeb;
    --side-nav-item-icon-color: #545e69;

    --disabled-background-color: #00000033;

    --bottom-nav: rgba(255, 255, 255, 0.6);
    --bottom-nav-text: #ababab;
    --bottom-plus: #FF7634;
    --bottom-plus-match-details: #68d64f;

    --input-background-color: #fafbfb;
    --input-border-color: #e4e9f0;
    --input-label-color: var(--text-light);
    --input-text-color: #505963;
    --input-valid-color: #388e3c;
    --input-invalid-color: #c62828;
    --input-disabled-background: rgb(222, 222, 224);
    --input-disabled-border-color: rgb(177, 179, 186);
    --input-disabled-color: #575757;
    --input-normal-color: #4ea7ff;
    --input-toggle-background: #dfe3e6;
    --input-toggle-handler-disabled: #888c8f;
    --input-toggle-handler-enabled: var(--secondary);
    --input-slider-track-background: #dadde3;

    --input-number-arrows-background: #e3e6ea;
    --input-number-arrows-active-background: #cfd5dc;
    --input-number-arrows-color: #3d444e;

    --input-select-background: #fff;
    --input-select-disabled-background: #f8f8f8;

    --normal-button-color: var(--primary-lightest);
    --subtle-button-background: #ffffff80;
    --subtle-button-color: #575757;
    --danger-button-background: #ff5b4f;
    --danger-button-color: #fff6f6;
    --inline-button-hover-background: #e1e3e6;

    --default-crest-color: #c4c4c4;

    --card-border-color: #dde4ed;
    --card-background-color: white;
    --card-hover-background-color: #f2f6fa;
    --card-active-background-color: #e6ecf3;
    --bottom-card-background: var(--secondary);
    --bottom-card-button: #38608F;
    --card-highlight-background: #fff;

    --card-category-color: #3b434a;

    --transfer-objective-background: #fff;

    --fab-background: var(--primary);
    --fab-color: white;

    --badge-background: #434b5b;
    --badge-color: #fff;

    --tab-sheet-background: #fff;
    --modal-background: rgba(0, 0, 0, 0.15);
    --modal-content-background: white;
    --tab-sheet-form-container-submit-background: white;

    --dismiss-hint-color: #dedede;

    --loader-progress-color: var(--primary);
    --loader-progress-background: #cfd1d4;

    --select-options-background: #fff;
    --select-options-shadow: 0px 5px 8px -4px rgba(50, 50, 50, 0.06),
                            -3px 5px 8px -4px rgba(50, 50, 50, 0.11),
                            3px 5px 8px -4px rgba(50, 50, 50, 0.11);

    --lineup-field-main: #ccf2c2;
    --lineup-text-color: #1b2418;
    --lineup-field-lines: #fff;
    --lineup-field-grass: repeating-linear-gradient(to bottom, #d7facd 0%, #d7facd 17.3%, #ccf2c2 17.3%, #ccf2c2 33.3%);
    --lineup-position-player-name-color: #41523d;

    --standard-home-team-color: #35cf21;
    --standard-away-team-color: #2586e8;

    --chart-bars-background: #d8dee5;
    --chart-bars-left: var(--standard-home-team-color);
    --chart-bars-right: var(--standard-away-team-color);

    --smooth-bars-left: #51b53a;
    --smooth-bars-right: #ff5b4f;

    --chart-line-background: #3C91E6;
    --chart-line-border: #3c91e663;

    --recent-form-background: #f2f2f2;

    --form-won-color: #35cf21;
    --form-lost-color: #f34134;
    --form-draw-color: #b9babd;

    --arrow-color: #2e2e2e;

    --transfer-fee-color: #51b53a;

    --manager-performance-background: #edf1f5;
    --manager-performance-bar-background: #edf1f5;
    --manager-performance-bar-hover-background: #dee6ee;
    --manager-performance-average-color: #ff9851;
    --manager-performance-bar-color: var(--form-won-color);
    --manager-performance-bar-hover-color: #2ec41a;

    --chart-background: #fff;

    --player-ratings-chart-background: #f2f6fa;

    --awful-rating-color: #db1212;
    --bad-rating-color: #f77d25;
    --decent-rating-color: #c9db21;
    --good-rating-color: #4bcc2f;
    --perfect-rating-color: #00b582;

    --player-transfer-value-color: #FFBA41;

    --inactive-priority-color: #a8aeb3;

    --tag-negative-background: #ff5b4f;
    --tag-negative-color: #f7eded;

    --tag-positive-background: #4bcc2f;
    --tag-positive-color: #eff7ed;

    --standard-tag-color: #ededed;
    --standard-tag-background: #3b3c40;

    --more-button-color: #d9d9d9;

    --close-button-background: #eeeeee;
    --close-button-color: #9f9f9f;

    --sheet-tab-icon-color: #c5d1d9;

    --small-create-button-background: #3C91E6;
    --small-create-button-color: #fff;
    --small-create-button-label: #c6c8cc;

    --feature-description-title: #3f4145;
    --feature-description-text: #5b5e63;

    --homescreen-header-background: #00acf1;
    --homescreen-gradient: linear-gradient(to right top, #68d64f, #00d098, #00c1d4, #00acf1, #3c91e6);

    --knockout-tree-background: #ededed;
    --knockout-square-background: #fff;
    --knockout-square-final-border: #f2b141;

    --feature-preview-icon-color: #4d4e52;

    --scrollable-tags-box-shadow-color: rgba(179, 179, 179, 0.5);

    --horizontal-select-background: #e1eaf2;
    --horizontal-select-selected-background: #fafdff;
    --horizontal-select-color: #8da2b3;
    --horizontal-select-selected-color: #738899;

    --bottom-nav-app-drawer-background: var(--primary);
    --bottom-nav-app-drawer-color: white;

    --match-view-mode-border: #e3e3e3;
    --match-view-mode-selected-background: var(--primary-lightest);
    --match-view-mode-selected-color: var(--text);

    --goal-distribution-chart-shade-0: #95e18a;
    --goal-distribution-chart-shade-1: #6bd25c;
    --goal-distribution-chart-shade-2: #4ebf3d;
    --goal-distribution-chart-shade-3: #36b024;
    --goal-distribution-chart-shade-4: #259e13;
}