body.dark {
    --meta-theme-color: #3C91E6;

    --default-fonts: -apple-system, "Roboto", Arial, Helvetica, sans-serif;
    --title-fonts: var(--default-fonts);
    --third-font: "Roboto Condensed", Arial, Helvetica, sans-serif; 

    --body-background-color: black;

    --primary: #3C91E6;
    --primary-lighter: #e0eeff;
    --primary-lightest: #f2f8ff;
    
    --secondary: #3784D1;

    --ternary: #ffb22e;
    --ternary-lighter: #F9F871;
    --ternary-darker: #FF7149;

    --accent-color-1: #F45B69;
    --accent-color-2: #88498F;

    --text: #dfe2e8;
    --text-subtle: #e3e3e3;
    --text-light: #94979c;
    --text-lightest: #f0f0f5;
    --text-label: #f2f3f5;
    --text-label-darker: #bfc2c7;

    --text-ternary: #fff2de;
    --text-ternary-lighter: #ede7dd;
    --text-ternary-darker: #ffe4b8;

    --header: rgb(60, 145, 230);;
    --header-text: white;
    --header-match-details: rgb(104, 214, 79);

    --match-story-line-background: #388e3c;
    --match-story-line-pause-point-background: white;
    --match-story-line-pause-point-border: #388e3c;
    --match-story-line-pin-background: #818694;

    --match-best-players: #3b3b3d;
    --match-best-players-rating-background: #232324;

    --side-nav-backdrop-color: #00000080;
    --side-nav-background: #212224;
    --side-nav-footer-background: #212224;
    --side-nav-item-background: #212224;
    --side-nav-item-hover: #212224;
    --side-nav-item-icon-color: #ced0d9;

    --disabled-background-color: #00000033;

    --bottom-nav: rgba(0, 0, 0, 0.4);
    --bottom-nav-text: #ababab;
    --bottom-plus: #FF7634;
    --bottom-plus-match-details: #68d64f;

    --input-background-color: transparent;
    --input-label-color: #f2f3f5;
    --input-valid-color: #388e3c;
    --input-invalid-color: #c62828;
    --input-disabled-background: rgb(222, 222, 224);
    --input-disabled-border-color: rgb(177, 179, 186);
    --input-normal-color: #e8f2fa;
    --input-toggle-background: #dfe3e6;
    --input-toggle-handler-disabled: #888c8f;
    --input-toggle-handler-enabled: var(--secondary);
    --input-slider-track-background: #dadde3;

    --input-number-arrows-background: #393a40;
    --input-number-border-color: #494b52;
    --input-number-arrows-color: #b9bcc7;

    --input-select-background: #212224;
    --input-select-disabled-background: #2e2e2f;

    --subtle-button-background: #ffffff80;
    --subtle-button-color: var(--text);
    --danger-button-background: #ff5b4f;
    --danger-button-color: white;
    --inline-button-hover-background: #5c5d5e;

    --default-crest-color: #c4c4c4;

    --card-border-color: #3f3f42;
    --card-background-color: #000000;
    --card-hover-background-color: #313131;
    --bottom-card-background: var(--secondary);
    --bottom-card-button: #38608F;
    --card-highlight-background: #212224;

    --save-card-pinned-background: #FF7149;
    --save-card-pinned-color: #dfe2e8;

    --card-category-color: #dfe2e8;

    --transfer-objective-background: #212224;

    --fab-background: var(--primary);
    --fab-color: white;

    --badge-background: #434b5b;
    --badge-color: #fff;

    --tab-sheet-background: #212224;
    --modal-background: #00000066;
    --modal-content-background: #212224;
    --tab-sheet-form-container-submit-background: white;

    --dismiss-hint-color: #dedede;

    --loader-progress-color: var(--primary);
    --loader-progress-background: #cfd1d4;

    --select-options-background: #212224;
    --select-options-shadow: 0px 5px 8px -4px rgba(0, 0, 0, 0.5),
                            -3px 5px 8px -4px rgba(0, 0, 0, 0.603),
                            3px 5px 8px -4px rgba(0, 0, 0, 0.63);

    --lineup-field-main: #000000;
    --lineup-text-color: var(--text);
    --lineup-field-lines: rgb(85, 85, 85);
    --lineup-field-grass: repeating-linear-gradient(to bottom, #000000 0%, #000000 17.3%, #242424 17.3%, #242424 33.3%);
    --lineup-position-player-name-color: var(--text);

    --standard-home-team-color: #51ad3d;
    --standard-away-team-color: #2a7ac9;

    --chart-bars-background: #d9d9d9;
    --chart-bars-left: var(--standard-home-team-color);
    --chart-bars-right: var(--standard-away-team-color);

    --smooth-bars-left: #51b53a;
    --smooth-bars-right: #db3e32;

    --chart-line-background: #3C91E6;
    --chart-line-border: #3c91e663;

    --recent-form-background: #3b3b3d;

    --form-won-color: #51b53a;
    --form-lost-color: #db3e32;
    --form-draw-color: #94979c;

    --arrow-color: #94979c;

    --transfer-fee-color: #51b53a;

    --manager-performance-background: #303031;
    --manager-performance-bar-background: #2b2b2c;
    --manager-performance-average-color: #be1d1d;

    --chart-background: #2b2b2c;

    --chart-item-color: #3C91E6;
    --chart-border-color: orange;

    --player-ratings-chart-background: #2b2b2c;

    --awful-rating-color: #db1212;
    --bad-rating-color: #f77d25;
    --decent-rating-color: #c9db21;
    --good-rating-color: #4bcc2f;
    --perfect-rating-color: #00b582;

    --player-transfer-value-color: #FFBA41;

    --inactive-priority-color: #a8aeb3;

    --tag-negative-background: #ff5b4f;
    --tag-negative-color: #f7eded;

    --tag-positive-background: #4bcc2f;
    --tag-positive-color: #eff7ed;

    --standard-tag-color: #ededed;
    --standard-tag-background: #3b3c40;

    --more-button-color: #d9d9d9;

    --close-button-background: #565656;
    --close-button-color: #afafaf;

    --sheet-tab-icon-color: #c5d1d9;

    --small-create-button-background: #3C91E6;
    --small-create-button-color: #fff;
    --small-create-button-label: #c6c8cc;

    --feature-description-title: #bdbfc4;
    --feature-description-text: #94979c;

    --homescreen-gradient: linear-gradient(to right top, #68d64f, #00d098, #00c1d4, #00acf1, #3c91e6);

    --knockout-tree-background: #000;
    --knockout-square-background: #1e1e1f;
    --knockout-square-final-border: #f2b141;

    --feature-preview-icon-color: #a8abb5;

    --scrollable-tags-box-shadow-color: #242424;

    --horizontal-select-background: #42494f;
    --horizontal-select-selected-background: #5a6066;
    --horizontal-select-color: #abb9c4;
    --horizontal-select-selected-color: #bcc5cc;

    --bottom-nav-app-drawer-background: var(--primary);
    --bottom-nav-app-drawer-color: white;

    --match-view-mode-border: #f1f1f1;
    --match-view-mode-selected-background: #e0eeff;
    --match-view-mode-selected-color: #040e11;

    --goal-distribution-chart-shade-0: #81d67d;
    --goal-distribution-chart-shade-1: #6dc069;
    --goal-distribution-chart-shade-2: #4fae49;
    --goal-distribution-chart-shade-3: #469c40;
    --goal-distribution-chart-shade-4: #2d7c27;
}