@media screen and (min-width: 1000px) {
    .team-screen .latest-matches {
        display: grid;
        grid-template-rows: 1fr;
        grid-template-columns: 60% 40%;
    }

    .team-screen .latest-matches .team-recent-form {
        grid-row: 1 / 1;
        grid-column: 2 / 3;
    }

    .team-screen .latest-matches .tab-component {
        grid-row: 1 / 1;
        grid-column: 1 / 2;
    }

    .team-screen .latest-matches .team-recent-form .wrapper .match .difficulty-bar {
        height: 230px;
    }
}