@media screen and ( min-width: 1000px ) {
    .lineups {
        display: flex;
        flex-direction: row;
    }

    .lineups .lineup:nth-child(2) {
        flex-direction: column;
    }

    .lineups .lineup .positions {
        padding-top: 500px;
    }

    .lineups .lineup .positions .positions-container {
        flex-direction: row;
    }

    .lineups .lineup:nth-child(2) .positions .positions-container {
        flex-direction: row-reverse;
    }

    .lineups .lineup .positions .positions-container .position-row {
        flex-direction: column-reverse;
        height: 100%;
    }

    .lineups .lineup:nth-child(2) .positions .positions-container .position-row {
        flex-direction: column-reverse;
        height: 100%;
    }

    .lineups .lineup .positions .gk-box {
        padding-top: unset;
        width: unset;

        left: 0;
        top: 50%;
        height: 50%;
        padding-right: 20%;
        transform: translateY(-50%);
    }

    .lineups .lineup:nth-child(2) .positions .gk-box {
        left: unset;
        right: 0;
        top: 50%;
        bottom: unset;
    }

    .lineups .lineup .positions .gk-box .small-box {
        width: unset;
        padding-top: unset;
        left: unset;

        height: 45%;
        padding-right: 40%;

        top: 50%;
        transform: translateY(-50%);
    }

    .lineups .lineup:nth-child(2) .positions .gk-box .small-box {
        right: -3px;
        bottom: 3px;
    }

    .lineups .lineup .positions .gk-box .half-circle {
        border-radius: unset;

        border-top-right-radius: 1000px;
        border-bottom-right-radius: 1000px;

        padding-right: 25%;
        height: 35%;
        left: 100%;
        top: 50%;

        transform: translateY(-50%);
    }

    .lineups .lineup:nth-child(2) .positions .gk-box .half-circle {
        top: 50%;
        left: 0;
        transform: translateY(-50%) translateX(-100%) rotate(180deg);
    }

    .lineups .lineup .positions .middle-circle {
        left: unset;
        right: -3px;
        top: 50%;
        width: 15%;
        height: 20%;

        transform: translateY(-50%);

        border-radius: 1000px 0 0 1000px;
    }

    .lineups .lineup:nth-child(2) .positions .middle-circle {
        left: -3px;
        top: 50%;
        transform: translateY(-50%) rotate(180deg);
    }

    .lineups .lineup .positions .positions-container .position-row .lineup-position-item {
        width: 32px;
    }

    .lineups .lineup .positions .positions-container .position-row .lineup-position-item svg {
        height: 32px;
    }
}