.match-story {
    position: relative;

    width: 100%;

    padding: 0 10px;
    box-sizing: border-box;
}

.match-story .match-story-line {
    position: relative;

    width: 100%;
    height: 4px;

    border-radius: 5px;

    margin: 48px 0;

    background-color: var(--match-story-line-background);

    transform-origin: left;
    animation: open-story-line .5s cubic-bezier(0.25, 1, 0.5, 1);
    animation-fill-mode: forwards;
}

.match-story .match-story-line::before {
    content: '';

    position: absolute;
    z-index: 1;

    top: -4px;
    left: 0;

    width: 4px;
    height: 4px;

    background-color: var(--match-story-line-pause-point-background);
    border: solid 4px var(--match-story-line-pause-point-border);

    border-radius: 500px;
}

.match-story .match-story-line::after {
    content: '';

    position: absolute;
    z-index: 1;

    top: -4px;
    right: 0;

    width: 4px;
    height: 4px;

    background-color: var(--match-story-line-pause-point-background);
    border: solid 4px var(--match-story-line-pause-point-border);

    border-radius: 500px;
}

.match-story .match-story-line .story-match-fact {
    position: absolute;
    top: 50%;
    transform: translateY(-50%) translateX(-50%);

    width: 14px;
    opacity: 0;

    animation: story-item-appear .5s cubic-bezier(0.34, 1.56, 0.64, 1);
    animation-fill-mode: forwards;
}

.match-story .match-story-line .story-match-fact.away {
    animation: story-item-appear-away .5s cubic-bezier(0.34, 1.56, 0.64, 1);
    animation-fill-mode: forwards;
}

.match-story .match-story-line .story-match-fact .fact-icon img {
    width: 14px;
    height: 14px;
}

.match-story .match-story-line .story-match-fact.away .story-pin {
    position: absolute;

    top: -9px;
    left: 50%;

    transform: translateX(-50%);

    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 4px 6px 4px;
    border-color: transparent transparent var(--match-story-line-pin-background) transparent;
}

.match-story .match-story-line .story-match-fact.home .story-pin {
    position: absolute;

    bottom: -9px;
    left: 50%;

    transform: translateX(-50%);

    width: 0;
    height: 0;
    border-style: solid;
    border-width: 6px 4px 0 4px;
    border-color: var(--match-story-line-pin-background) transparent transparent transparent;
}

@keyframes open-story-line {
    from {
        transform: scaleX(0);
    }
    to {
        transform: scaleX(1);
    }
}

@keyframes story-item-appear {
    from {
        opacity: 0;
        transform: translateY(-100%) translateX(-50%);
    }

    to {
        opacity: 1;
        transform: translateY(calc(-50% - 2px)) translateX(-50%);
    }
}

@keyframes story-item-appear-away {
    from {
        opacity: 0;
        transform: translateY(0%) translateX(-50%);
    }

    to {
        opacity: 1;
        transform: translateY(calc(-50% + 2px)) translateX(-50%);
    }
}