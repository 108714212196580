.horizontal-select {
    position: relative;

    margin: 0 10px;
    width: calc(100% - 20px);

    background-color: var(--horizontal-select-background);
    border-radius: 1000px;
}

.horizontal-select .options {
    position: relative;
    display: flex;
    flex-direction: row;

    width: 100%;

    padding: 14px 10px;
    box-sizing: border-box;
}

.horizontal-select .options .horizontal-select-option {
    display: inline-flex;
    justify-content: center;

    position: relative;
    width: 100%;

    cursor: pointer;
}

.horizontal-select .options .horizontal-select-option::after {
    content: '';
    position: absolute;
    top: 50%;
    left: -4px;

    width: calc(100% + 8px);
    height: calc(100% + 20px);

    transform: translateY(-50%) scale(0);
    opacity: 0;

    border-radius: 50px;
    box-shadow: 0px 4px 1px -3px rgba(50, 50, 50, 0.08);
    background-color: var(--horizontal-select-selected-background);

    transition: all .25s cubic-bezier(0.34, 1.56, 0.64, 1);
}

.horizontal-select .options .horizontal-select-option.selected::after {
    content: '';
    position: absolute;
    top: 50%;
    left: -4px;

    width: calc(100% + 8px);
    height: calc(100% + 20px);

    transform: translateY(-50%) scale(1);
    opacity: 1;

    border-radius: 50px;
    box-shadow: 0px 4px 1px -3px rgba(50, 50, 50, 0.08);
    background-color: var(--horizontal-select-selected-background);
}

.horizontal-select .options .horizontal-select-option.selected p {
    position: relative;
    z-index: 1;
    color: var(--horizontal-select-selected-color);
}

.horizontal-select .options .horizontal-select-option p {
    text-transform: uppercase;
    font-weight: 500;
    font-size: .8rem;
    color: var(--horizontal-select-color);
}

.horizontal-select .options p {
    margin: 0;
}