@media screen and (min-width: 1000px) {
    .manager-tenures {
        display: grid;
        grid-template-rows: auto;
        grid-template-columns: 1fr 1fr;
    }

    .manager-tenures .manager-stats {
        grid-row: 1 / 2;
        grid-column: 1 / 2;
    }

    .manager-tenures > h4,
    .manager-tenures > .tenure-graph,
    .manager-tenures > .tenure-cards {
        grid-row: 1 / 2;
        grid-column: 2 / 3;
    }
}