.simple-field-sort {
    display: grid;
    grid-template-columns: 30% 70%;
    align-items: center;
    position: relative;
    width: 100%;
    padding: 10px;

    box-sizing: border-box;
    background-color: var(--card-background-color);
}

.simple-field-sort > p {
    display: inline-flex;
    justify-self: flex-start;
}

.simple-field-sort .toggles {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
}

.simple-field-sort .sort-toggle {
    cursor: pointer;
    position: relative;
    font-size: 1rem;

    min-width: 50px;
    max-width: calc(50% - 32px);
    padding: 8px 16px;
    margin: 2px 8px;

    background-color: var(--standard-tag-color);
    border-radius: 500px;

    text-align: center;

    box-sizing: border-box;
}

.simple-field-sort .sort-toggle p {
    margin: 0;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}