.fader {
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 20vh;
    background-image: linear-gradient(to bottom, rgba(0, 172, 241, 0), rgb(0, 172, 241));
}

.features {
    background-image: linear-gradient(to top, #68d64f, #00d098, #00c1d4, #00acf1);

    box-sizing: border-box;
    padding-bottom: 40px;
}

.fs-container {
    position: relative;
    z-index: 2;
    width: 100%;
    max-width: 850px;
    margin: 0 auto 40px auto;

    display: grid;

    align-items: center;

    overflow: visible;
}

.fs-container::before {
    content: '';
    z-index: -1;
    position: absolute;

    width: 100%;
    height: 100%;
    background-color: white;

    border-radius: 20px;

    box-shadow: 0 15px 35px -15px rgba(15, 15, 15, 0.12),
                0 2px 5px -1px rgba(15, 15, 15, 0.09);
}


.fs-container .fs-title span.fs-highlight {
    display: inline;
    text-decoration: none;
    background-image: linear-gradient(transparent, transparent),linear-gradient(transparent, transparent),linear-gradient(to left, #68d64f, #00d098, #00c1d4, #00acf1, #3c91e6);
    background-repeat: no-repeat;
    background-position: 120%, 122%, 0 130%;

    background-size: 100% 9px;
}

@media screen and (min-width: 1000px) {
    .homescreen-header .content {
        display: grid;
        grid-template-columns: 3fr 2fr;
        gap: 15px;
    }

    .homescreen-header .content .images-wrapper {
        position: relative;
        height: 100%;
    }

    .homescreen-header .content .images-wrapper img {
        position: absolute;
        display: block;
    }

    .homescreen-header .content .images-wrapper img:last-child {
        max-height: 100%;
    }

    .fs-container {
        grid-template-columns: 3fr 1fr;
    }

    .fs-container:nth-child(odd) {
        grid-template-columns: 1fr 3fr;
    }

    .fs-container .fs-image-container {
        position: relative;
    }

    .fs-container:nth-child(1)::before {
        transform: scaleX(1) scaleY(1) scaleZ(1) rotateX(-4deg) rotateY(0deg) rotateZ(-2deg) translateX(0px) translateY(0px) translateZ(0px) skewX(0deg) skewY(0deg);
    }

    .fs-container:nth-child(2)::before {
        transform: scaleX(1) scaleY(1) scaleZ(1) rotateX(8deg) rotateY(0deg) rotateZ(1deg) translateX(0px) translateY(0px) translateZ(0px) skewX(0deg) skewY(0deg);
    }

    .fs-container:nth-child(3)::before {
        transform: scaleX(1) scaleY(1) scaleZ(1) rotateX(-7deg) rotateY(8deg) rotateZ(-2deg) translateX(0px) translateY(0px) translateZ(0px) skewX(0deg) skewY(0deg);
    }

    .fs-container:nth-child(4)::before {
        transform: scaleX(1) scaleY(1) scaleZ(1) rotateX(-29deg) rotateY(-2deg) rotateZ(0deg) translateX(0px) translateY(0px) translateZ(0px) skewX(0deg) skewY(0deg);
    }

    .fs-container:nth-child(5)::before {
        transform: scaleX(1) scaleY(1) scaleZ(1) rotateX(17deg) rotateY(-2deg) rotateZ(-2deg) translateX(0px) translateY(0px) translateZ(0px) skewX(0deg) skewY(0deg);
    }

    .fs-container:nth-child(odd) > div:first-child {
        order: 2;
    }

    .fs-container:nth-child(odd) > div:nth-child(2) {
        order: 1;
    }

    .fs-container .fs-image-container {
        position: relative;
        min-height: 500px;
        overflow: visible;
    }

    .fs-container .fs-image-container img {
        position: absolute;
        height: 100%;
        max-width: unset;
    }

    .fs-container .fs-image-container img {
        right: -25%;
        bottom: 10%;
    }

    .fs-container:nth-child(odd) .fs-image-container img {
        left: -25%;
        top: 10%;
    }
}

@media screen and (max-width: 1000px) {
    .fader {
        height: 5vh;
    }

    .homescreen-header .images-wrapper {
        display: none;
    }

    .features {
        scroll-snap-type: x mandatory;
        overflow-x: scroll;
        display: flex;
        min-width: 100%;
    }

    .features .fs-container {
        scroll-snap-align: center;
        min-width: calc(90% - 30px);
        margin-right: 20px;
    }

    .features .fs-container::before {
        overflow-y: visible;
    }

    .features .fs-container:first-child {
        margin-left: 20px;
    }
}