.goal-distribution-chart {
    display: grid;
    grid-template-columns: 22px 1fr 60%;
    gap: 10px;
    align-items: center;

    margin: 5px 10px;
}

.goal-distribution-chart .crest {
    width: 22px;
    height: 22px;
}

.goal-distribution-chart .total p {
    text-align: right;
    font-size: .7rem;
    font-weight: bold;
    margin: 0;
    color: var(--transfer-fee-color);
}

.goal-distribution-chart .chart {
    display: grid;
    grid-template-columns: repeat(6, 1fr);

    font-size: .7rem;
    font-weight: 500;
    color: white;
    text-align: center;
}

.goal-distribution-chart.legend .labels {
    font-size: .6rem;
}

.goal-distribution-chart.legend .labels {
    position: relative;
    color: var(--text-label);
    font-weight: bold;
    text-align: left;
    height: .6rem;
}

.goal-distribution-chart.legend .labels p {
    position: absolute;
    margin: 0;
}

.goal-distribution-chart .chart .distribution-square {
    text-overflow: fade;
    white-space: nowrap;
    overflow: hidden;
}

.goal-distribution-chart .chart .distribution-square p {
    margin: 8px;
}

.goal-distribution-chart .chart .distribution-square.shade-0 {
    background-color: var(--goal-distribution-chart-shade-0);
}

.goal-distribution-chart .chart .distribution-square.shade-1 {
    background-color: var(--goal-distribution-chart-shade-1);
}

.goal-distribution-chart .chart .distribution-square.shade-2 {
    background-color: var(--goal-distribution-chart-shade-2);
}

.goal-distribution-chart .chart .distribution-square.shade-3 {
    background-color: var(--goal-distribution-chart-shade-3);
}

.goal-distribution-chart .chart .distribution-square.shade-4 {
    background-color: var(--goal-distribution-chart-shade-4);
}