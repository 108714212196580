.match-meta .row {
    gap: 10px;
}

.match-competition-change .content {
    min-height: 380px;
}

.meta-best-players .row {
    display: flex;
    flex-direction: row;
}

.meta-best-players .row > div:first-child {
    width: 70%;
}

.meta-best-players .row > div:last-child {
    width: 30%;
}