.search-window {
    display: none;

    position: fixed;

    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    z-index: 10000;

    transform-origin: bottom right;

    background: var(--modal-content-background);

    overflow-y: scroll;

    padding-top: env(safe-area-inset-top);
}

.search-window-fab {
    display: none;

    z-index: 100001;

    bottom: 15px;
    margin-bottom: env(safe-area-inset-bottom);

    transform-origin: bottom right;
}

.search-window-fab-enter {
    display: flex;

    transform: scale(0);
    opacity: 0.2;
}

.search-window-fab-enter-active {
    display: flex;

    transform: scale(1);
    opacity: 1;

    transition: all .25s cubic-bezier(0.16, 1, 0.3, 1);
}

.search-window-fab-enter-done {
    display: flex;

    transform: scale(1);
    opacity: 1;
}

.search-window-fab-exit {
    display: flex;

    transform: scale(1);
    opacity: 1;
}

.search-window-fab-exit-active {
    display: flex;

    transform: scale(.2);
    opacity: 0;

    transition: all .25s cubic-bezier(0.7, 0, 0.84, 0);
}

.search-window-fab-exit-done {
    display: none;

    transform: scale(.2);
    opacity: 0;
}

.search-window.enter {
    display: block;

    transform: scale(0);
    opacity: 0.2;
    border-top-left-radius: 500px;
    border-top-right-radius: 500px;
}

.search-window.enter-active {
    display: block;

    transform: scale(1);
    opacity: 1;
    border-top-left-radius: 0;
    border-top-right-radius: 0;

    transition: all .25s cubic-bezier(0.16, 1, 0.3, 1);
}

.search-window.enter-done {
    display: block;

    transform: scale(1);
    opacity: 1;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
}

.search-window.exit {
    display: block;

    transform: scale(1);
    opacity: 1;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
}

.search-window.exit-active {
    display: block;

    transform: scale(.2);
    opacity: 0;
    border-top-left-radius: 500px;
    border-top-right-radius: 500px;

    transition: all .25s cubic-bezier(0.7, 0, 0.84, 0);
}

.search-window.exit-done {
    display: none;

    transform: scale(.2);
    opacity: 0;
    border-top-left-radius: 500px;
    border-top-right-radius: 500px;
}

.search-window .input-field {
    position: sticky;
    top: 0;
    z-index: 20;

    background-color: var(--modal-content-background);
}

.search-window .search-results {
    position: relative;

    box-sizing: border-box;

    padding: 0 10px;
}

.search-window .search-results .search-result {
    padding: 14px 0;
    box-sizing: border-box;

    border-bottom: solid 1px var(--card-border-color);
}

.search-window .search-results .search-result:last-child {
    border-bottom: none;
}

.search-window .search-results .search-result > p {
    margin-top: 0;
    margin-bottom: 14px;
}

.search-window .search-results .search-result .footer > p {
    font-size: .7rem;
    font-weight: 500;
    text-transform: uppercase;

    color: var(--text-light);

    margin: 0 0 5px 0;
}

.search-window .search-results .search-result .tag {
    margin-right: 5px;
}

.search-window .search-results .search-result .tag:last-child {
    margin-right: 0;
}

.search-window .search-results .search-result .tag.match_facts {
    background-color: var(--header-match-details);
}

.search-window .search-results .search-result .tag.transfers {
    background-color: var(--primary);
}

.search-window .search-results .search-result .tag.lineup_positions {
    background-color: var(--ternary);
}

.search-window .search-results .search-result .tag.matches {
    background-color: var(--accent-color-1);
}

.search-window .search-results .search-result .tag.standing_positions {
    background-color: var(--accent-color-2);
}
