.match-view-mode-input {
    min-width: 100%;
}

.match-view-mode-input .scrollable {
    position: relative;
    overflow-x: scroll;
    scroll-snap-type: x mandatory;

    width: 100%;
    height: 240px;
    max-height: 240px;
    box-sizing: border-box;

    padding: 10px 2px;

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;

    gap: 20px;
}

.match-view-mode-input .scrollable .match-view-mode-option {
    position: relative;
    min-width: 90%;
    height: 100%;

    padding: 15px 10px;
    box-sizing: border-box;
    border: solid 2px var(--match-view-mode-border);
    border-radius: 10px;

    scroll-snap-align: center;

    display: grid;
    grid-template-rows: 1fr 40px;
}

.match-view-mode-input .scrollable .match-view-mode-option .example .match-view-mode {
    position: relative;
    display: block;

    width: 80%;
    aspect-ratio: 400/213;

    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;

    margin-left: 10%;

    border-radius: 10px;
}

.match-view-mode-input .scrollable .match-view-mode-option .toggle {
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    justify-content: center;
    font-weight: 500;
    gap: 7px;
}

.match-view-mode-input .scrollable .match-view-mode-option .toggle input[type="radio"] {
    position: relative;
    width: 16px;
    height: 16px;
    border: solid 1px var(--match-view-mode-border);
    border-radius: 500px;
    display: block;
    box-sizing: border-box;
}

.match-view-mode-input .scrollable .match-view-mode-option .toggle input[type="radio"]:checked {
    border: solid 1px var(--primary);
}

.match-view-mode-input .scrollable .match-view-mode-option .toggle input[type="radio"]:checked::after {
    content: '';
    position: absolute;

    width: 10px;
    height: 10px;
    top: 2px;
    left: 2px;

    border-radius: 100px;

    background-color: var(--primary);
}

.match-view-mode-input .scrollable .match-view-mode-option.selected {
    box-shadow: 0px 0px 5px 1px rgba(0, 132, 255, 0.31);
    border: solid 2px var(--primary);
    background-color: var(--match-view-mode-selected-background);
    color: var(--match-view-mode-selected-color);
}