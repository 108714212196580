@media screen and (min-width: 1000px) {
    .league-screen .matches {
        display: grid;
        grid-template-rows: 1fr;
        grid-template-columns: 60% 40%;
    }

    .league-screen .matches .featured-match-wrapper {
        grid-row: 1 / 1;
        grid-column: 2 / 3;
    }

    .league-screen .matches .tab-component {
        grid-row: 1 / 1;
        grid-column: 1 / 2;
    }

    .league-screen .standing-screen .standing-container {
        width: 500px;
        max-width: 100%;
        margin: 0 auto;
    }
}