.refreshing-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.ptr__pull-down--pull-more {
    text-align: center;
    margin-top: 20px;
    color: var(--text-light);
    font-weight: 500;
}

.ptr__loader .ptr__pull-down--loading {
    margin: 20px auto;
}

.refreshing-wrapper .loader.circular {
    margin-top: 20px;
}

