:root {
    /* Variables for generic sizing */
    --padding: 10px;
    --margin: 10px;
    
    --button-padding: 12px 35px;
}

* {
    transition: border .2s ease-in-out, background .2s ease-in-out;
}

html, body {
    margin: 0;
    font-size: 16px;
    width: 100%;
    min-height: calc(100vh + env(safe-area-inset-top));
    color: var(--text);
}

input {
    -webkit-appearance: none;
}

body {
    background-color: var(--body-background-color);
    font-family: var(--default-fonts);
    font-weight: 400;
}

p {
    word-wrap: break-word;
    text-overflow: ellipsis;
    text-rendering: geometricPrecision;
}

a {
    color: inherit;
    text-decoration: none;
}

h4 {
    text-align: center;
    font-size: .9rem;
    letter-spacing: 0.2px;
}

h5 {
    text-align: center;
    font-size: .9rem;
    font-weight: 500;
    color: var(--text-label-darker);
    letter-spacing: -0.2px;
    margin: 0 10px 15px 10px;
}

.header {
    position: fixed;
    display: grid;
    grid-template-columns: 40px auto 40px;
    align-items: center;

    z-index: 100;

    top: 0;
    left: 0;

    width: 100%;
    height: 55px;
    
    background-color: var(--header);
    color: var(--header-text);

    padding-top: env(safe-area-inset-top);
}

.header .title img {
    max-height: 28px;
    max-width: 28px;
}

.header .title .header-title-normal {
    animation: header-title-disappear .2s ease-in-out;
}

.header .title .header-title-secondary {
    display: flex;
    flex-direction: row;
    gap: 10px;
    align-items: center;
    animation: header-title-appear .2s ease-in-out;
}

.header > * {
    display: flex;
    align-items: center;
    justify-content: center;
}

.header .options-header-text {
    display: flex;
    flex-direction: column;
}

.header .options-header-text p {
    margin: 0;
    text-align: center;
}

.header p.with-options {
    display: inline;
    font-size: .9rem;
    font-weight: 700;
}

.header p.options-select {
    display: flex;
    font-size: .8rem;
    align-items: center;
    justify-content: center;
}

.header p.options-select svg {
    width: .9rem;
    height: .9rem;
}

.header p,
.react-tabs__tab-list ul li {
    font-size: 1rem;
    font-weight: 500;
    letter-spacing: -0.1px;
}

.header-spacing {
    height: calc(54px + env(safe-area-inset-top));
}

.side-nav-wrapper {
    position: fixed;
    z-index: 101;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    display: none;

    transition: all .5s cubic-bezier(0.16, 1, 0.3, 1);
}

.side-nav-wrapper.enter {
    display: block;
}

.side-nav-wrapper.enter-active {
    display: block;
}

.side-nav-wrapper.enter-done {
    display: block;
}

.side-nav-wrapper.exit {
    display: block;
}

.side-nav-wrapper.exit-active {
    display: block;
}

.side-nav-wrapper .side-nav-background {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 0;
    background-color: var(--side-nav-backdrop-color);

    opacity: 0;

    transition: all .5s cubic-bezier(0.16, 1, 0.3, 1);
}

.side-nav-wrapper.enter-active .side-nav-background {
    opacity: 1;
}

.side-nav-wrapper.enter-done .side-nav-background {
    opacity: 1;
}

.side-nav-wrapper .side-nav {
    position: relative;
    top: 0;
    left: 0;
    width: 75%;
    height: 100%;
    background-color: var(--side-nav-background);

    padding: env(safe-area-inset-top) 0 env(safe-area-inset-bottom) env(safe-area-inset-left);

    transform: translateX(-100%);

    display: flex;
    flex-direction: column;
    justify-content: space-between;

    transition: all .5s cubic-bezier(0.16, 1, 0.3, 1);

    box-sizing: border-box;
}

.side-nav .side-nav-content {
    position: relative;
    max-height: 100%;
    overflow-y: scroll;
}

.side-nav-wrapper.enter-active .side-nav{
    transform: translateX(0);
}

.side-nav-wrapper.enter-done .side-nav{
    transform: translateX(0);
}

.side-nav-wrapper .side-nav .side-nav-header {
    position: relative;
    width: 100%;
    padding: 10px;
    box-sizing: border-box;
}

.side-nav-wrapper .side-nav .side-nav-footer {
    position: fixed;
    bottom: 0;
    z-index: 10;
    background: var(--side-nav-footer-background);
    width: 100%;
    border-top: solid 1px var(--card-border-color);

    padding-bottom: env(safe-area-inset-bottom);
    box-sizing: border-box;

    box-shadow: 0px 1px 4px -1px rgba(50,50,50,0.30);
}

.side-nav-wrapper .side-nav-item {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;

    position: relative;
    box-sizing: border-box;
    padding: 14px 10px;

    background-color: var(--side-nav-item-background);

    transition: all ease-in-out .15s;
}

.side-nav-wrapper .side-nav-item:hover {
    background-color: var(--side-nav-item-hover);
}

.side-nav-wrapper .side-nav-item svg {
    color: var(--side-nav-item-icon-color);
    width: 30px;
    margin-right: 5px;
}

.side-nav-wrapper .side-nav-item p {
    margin: 0;
}

.badge {
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translateY(-50%);

    padding: 4px 10px;
    border-radius: 3px;

    background-color: var(--badge-background);
}   

.badge p {
    margin: 0;
    font-size: .7rem;
    color: var(--badge-color);
}

.button {
    padding: var(--button-padding);
    font-size: 1.1rem;
    font-weight: 500;
    border-radius: 10px;
    outline: none;
    border: none;
    margin: 6px 0;
}

.button.button-primary {
    background-color: var(--primary);
    color: var(--normal-button-color);
}

.button.button-subtle {
    background-color: var(--ternary);
    color: var(--text);
}

.button.button-disabled {
    color: var(--text-light);
}

.button.button-subtle {
    font-size: .9rem;
    padding: 6px 10px;
    border-radius: 500px;

    background-color: var(--subtle-button-background);
    color: var(--subtle-button-color);
}   

.button.button-danger {
    background-color: var(--danger-button-background);
    color: var(--danger-button-color);
}

.button.button-subtle p {
    padding: 0;
    margin: 0;
}

.button.inline-button {
    cursor: pointer;
    font-weight: 600;
    font-size: 1rem;
    text-align: center;

    color: var(--secondary);
}

.button.inline-button:hover {
    background-color: var(--card-hover-background-color);
}

.row {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    position: relative;
    width: 100%;
}

.fs-grid-row {
    display: grid;
    grid-template-columns: auto;
    width: 100%;
    padding: 5px;
    box-sizing: border-box;
}

.fs-grid-row.position-group .input-select {
    grid-column: 1/3;
}

.card.bottom-card {
    position: fixed;
    bottom: 0;
    width: 100%;
    height: 30%;
    min-height: 250px;

    background-color: var(--bottom-card-background);
    color: var(--text-lightest);

    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    box-shadow: 0px 0px 5px -2px rgba(50,50,50,0.30),0px 0px 5px -2px rgba(50, 50, 50, 0.25);
}

.card.bottom-card > .card-body {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    height: 80%;
}

.card.bottom-card > .card-body {
    box-sizing: border-box;
    padding: 0 var(--padding);
}

.card.bottom-card .card-content {
    text-align: center;
}

.card.bottom-card .card-footer {
    width: 100%;
    height: 20%;
}

.button-signin {
    position: relative;
    z-index: 5;
    padding: 12px 16px;

    display: inline-flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    font-family: "Archivo", "Roboto", Arial, sans-serif;
    font-weight: 600;

    border-radius: 30px;
    color: white;

    width: 100%;
    background-color: #002b62;
    box-shadow: 0px 2px 17px 0px rgba(0, 0, 0, 0.25),0px 2px 6px -3px rgba(0, 0, 0, 0.25);
}

.card.card-category {
    position: relative;
    width: 100%;
    padding: 2px 15px;
    box-sizing: border-box;
    border-bottom: solid 1px var(--card-border-color);
    color: var(--card-category-color);
}

.callback-container {
    position: relative;
    width: 100%;
    height: calc(100vh - 55px);

    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: center;

    padding: 10px;
    box-sizing: border-box;

    text-align: center;
    font-size: .9rem;
}

img {
    position: relative;
    max-width: 100%;
}

.fab {
    position: fixed;
    bottom: calc(65px + env(safe-area-inset-bottom));
    right: 1rem;

    width: 3.4rem;
    height: 3.4rem;
    border-radius: 100px;

    background-color: var(--fab-background);
    box-shadow: 0px 0px 6px -3px rgba(0, 0, 0, 0.4),0px 0px 6px 0px rgba(59, 59, 59, 0.19);
    color: var(--fab-color);

    display: flex;
    align-items: center;
    justify-content: center;

    animation: fab-appear .2s cubic-bezier(0.34, 1.56, 0.64, 1);
}

.fab .icon {
    width: 1.7rem;
    height: 1.7rem;

    transition: all cubic-bezier(0.075, 0.82, 0.165, 1) .2s;
}

.icon.plus-open {
    transform: rotate(0deg);
}

.icon.plus-close {
    transform: rotate(225deg);
}

.tab-trigger {
    position: relative;
    width: 100%;
    display: flex;
    justify-content: center;
}

.tab {
    width: 100%;
}

.tab.tab-sheet {
    position: fixed;

    overflow-y: scroll;
    overscroll-behavior-y: contain;

    z-index: 101;

    height: calc(100% - 55px - 20px);
    bottom: 0;
    left: 0;

    background-color: var(--tab-sheet-background);
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;

    box-shadow: 0px 0px 6px 0px rgba(59, 59, 59, 0.19);

    transition: all cubic-bezier(0.16, 1, 0.3, 1) .3s;
}

.tab.tab-sheet .tab-sheet-content {
    display: flex;
    flex-flow: column;
    align-items: center;

    font-family: var(--default-fonts);

    min-height: calc(100% + 1px);
}

.tab.tab-sheet.invisible {
    bottom: -100%;
}

.tab.tab-sheet.visible {
    bottom: 0;
}

.tab.tab-sheet .dismiss-handler {
    position: relative;
    width: 100%;
    min-height: 30px;

    display: flex;
    align-items: center;
    justify-content: center;
}

.dismiss-handler .dismiss-hint {
    position: relative;
    width: 50px;
    height: 5px;

    border-radius: 100px;

    background-color: var(--dismiss-hint-color);
}

.form-container,
.form-wrapper {
    position: relative;
    width: 95%;
    margin-left: 2.5%;
    margin-right: 2.5%;
}

.form-container .submission-loader {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 10;
    background-color: rgba(240, 240, 240, .5);

    width: 100%;
    height: 100%;
}

.form-container .row {
    gap: 10px;
}

.form-container button {
    margin-bottom: 120px;
}

.label {
    margin: 0;
    font-weight: 500;
    color: var(--text-label);
    text-transform: uppercase;
    font-size: .7rem;
}

.input-field {
    position: relative;
    width: 100%;
    margin: 0 0 22px 0;
    padding-top: 8px;
}

.input-field.perma-placeholder {
    padding-top: unset;
}

.input-field.horizontal {
    position: relative;
    width: 100%;
    margin-bottom: 16px;
    box-sizing: border-box;
    display: grid;
    grid-template-columns: 70% 30%;
    align-items: center;
}

.input-field.horizontal label {
    position: relative;
    left: 0;
}

.input-field .actual-input {
    position: relative;
}

.input-field.perma-placeholder p{
    position: absolute;
    margin: 0;
    top: 0;
    padding: 10px 15px;
    font-size: 1.3rem;
    opacity: .5;
    z-index: -1;
    letter-spacing: 1.1px;
}

.input-field.perma-placeholder input {
    font-size: 1.3rem;
    letter-spacing: 1px;
    font-weight: 500;
    background: transparent;
}

.input-field input {
    font-family: var(--default-fonts);

    color: var(--input-text-color);
    background-color: var(--input-background-color);

    border: solid 1px var(--input-border-color);
    border-radius: 10px;

    width: 100%;
    padding: 16px 15px 15px 15px;
    box-sizing: border-box;
    font-size: 1.1rem;

    outline: none;

    transition: box-shadow .2s cubic-bezier(0.16, 1, 0.3, 1);
}

.input-field input.valid {
}

.input-field input.invalid {
}

.input-field.disabled {
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
}

.input-field input[disabled] {
    box-shadow: 0px 2px 0px -1px var(--input-disabled-border-color);
    background-color: var(--input-disabled-background);
    color: var(--text-light);
}

.input-field.disabled label {
    color: var(--text-light);
}

.input-field label {
    position: absolute;
    top: 50%;
    left: 15px;

    color: var(--input-label-color);
    font-weight: 500;

    transform: translateY(-50%);
    transform-origin: left;

    transition: all cubic-bezier(0.16, 1, 0.3, 1) .3s;
}

.input-field .clear-wrapper {
    position: absolute;
    right: 4px;
    bottom: 4px;
}

.input-field input:focus {
    border-color: var(--input-background-color);
    box-shadow: 0px 0px 0px 3px var(--input-normal-color);
}

.input-field input.valid:focus {
    box-shadow: 0px 0px 0px 3px var(--input-valid-color);
}

.input-field input.invalid:focus {
    box-shadow: 0px 0px 0px 3px var(--input-invalid-color);
}

.input-field input:focus + label, 
.input-field input:not(:placeholder-shown) + label {
    top: 0;
    font-size: .6rem;
    transform: translateY(20%);
}

.input-field .max-length-hint {
    font-size: .7rem;

    position: absolute;
    bottom: -5px;
    transform: translateY(100%);
    right: 5px;
}

.input-field .info {
    font-size: .75rem;
    font-weight: 500;
    color: var(--text-light);
}

.input-field .info p {
    margin: 6px
}

.input-with-completion {
    position: relative;
    width: 100%;
}

.input-with-completion .suggestions {
    position: absolute;
    width: 100%;
    background-color: var(--select-options-background);
    z-index: 15;
    box-shadow: 0px 5px 1px -3px rgba(103, 103, 103, 0),
                -4px 6px 6px -2px rgba(50, 50, 50, 0.14),
                4px 6px 6px -2px rgba(50, 50, 50, 0.14);
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    top: 100%;
}

.input-with-completion .suggestions .input-suggestion {
    border-bottom: solid 1px var(--card-border-color);
}

.input-with-completion .suggestions .input-suggestion p {
    margin: 15px 10px;
}

.input-with-completion .suggestions.hide {
    display: none;
}

.input-select {
    position: relative;
    width: 100%;
    margin-bottom: 30px;
    background-color: var(--input-select-background);
}

.input-select.shown {
    z-index: 10;
}

.input-select.shown .options-wrapper {
    margin-bottom: 120px;
}

.input-select .options-wrapper {
    position: relative;
    width: 100%;
    z-index: 5;
}

.input-select .options-wrapper .options {
    position: absolute;
    width: 100%;
    max-height: 280px;
    overflow-y: scroll;
    
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;

    background-color: var(--select-options-background);
    box-shadow: var(--select-options-shadow);

    opacity: .0;
    display: none;
    transform: translateY(-70px);

    transition: all cubic-bezier(0.16, 1, 0.3, 1) .25s;
}

.input-select .info {
    margin-top: 5px;
    font-size: .8rem;
    font-weight: 500;
    color: var(--text-light);
}

.options-wrapper .options.enter {
    display: block;
}

.options-wrapper .options.enter-active {
    opacity: 1;
    transform: translateY(0);
}

.options-wrapper .options.enter-done {
    opacity: 1;
    transform: translateY(0);
    display: block;
}

.options-wrapper .options.exit {
    display: block;
}

.options-wrapper .options.exit-done {
    display: none;
}

.input-select .option,
.full-screen-select-sheet .option {
    position: relative;
    width: 100%;

    background-color: var(--select-options-background);

    padding: 20px 10px;
    box-sizing: border-box;

    border-bottom: solid 1px var(--card-border-color);

    cursor: pointer;

    transition: background-color .25s ease-in-out;
}

.input-select .option:hover,
.full-screen-select-sheet .option:hover {
    background-color: var(--side-nav-item-hover);
}

.input-select .option.with-icon,
.full-screen-select-sheet .option.with-icon {
    display: grid;
    grid-template-columns: 44px auto;
    align-items: center;
}

.input-select .option.with-icon svg,
.input-select .option.with-icon .fact-icon,
.full-screen-select-sheet .option.with-icon svg,
.full-screen-select-sheet .option.with-icon .fact-icon {
    margin: 0 auto;
}

.input-select .option.with-icon .fact-icon,
.full-screen-select-sheet .option.with-icon .fact-icon {
    width: 18px;
    min-width: unset;
}

.input-select .option.default.disabled {
    position: relative;
    z-index: 5;
}

.input-select .option.default.disabled p{
    position: relative;
    width: 95%;
}

.input-select .option.default.disabled svg:last-child {
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
}

.input-select p,
.full-screen-select-sheet .option p {
    margin: 0;
}

.input-select.with-categories .options-wrapper .options .option:not(.category) p{
    margin-left: 27px;
}

.input-select .option.category,
.full-screen-select-sheet .option.category {
    display: grid;
    grid-template-columns: 20px auto;
    gap: 7px;
    align-items: center;

    font-weight: 500;

    cursor: auto;
    background-color: var(--input-select-disabled-background);
}

.input-select .option.category .crest.international {
    border: none;
}

.input-toggle {
    display: grid;
    grid-template-columns: auto 55px;

    margin: 25px auto;
    padding: 0 10px;

    width: 100%;

    box-sizing: border-box;
}

.input-toggle label {
    padding-bottom: 10px;
    border-bottom: dashed 1px var(--card-border-color);

    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 15px;
}

.input-toggle label img {
    max-width: 24px;
    max-height: 24px;
}

input[type="checkbox"] {
    position: relative;
    width: 45px;
    height: 16px;
    background-color: var(--input-toggle-background);
    border-radius: 50px;
    border: none;
    outline: none;
}

input[type="checkbox"]::after {
    content: '';
    position: absolute;
    width: 22px;
    height: 22px;
    border-radius: 50px;
    background-color: var(--input-toggle-handler-disabled);
    z-index: 2;

    transform: translateY(-50%);
    top: 50%;
    left: 0%;

    transition: left cubic-bezier(0.83, 0, 0.17, 1) .2s, background-color cubic-bezier(0.83, 0, 0.17, 1) .2s;
}

input[type="checkbox"]:checked::after {
    content: '';
    transform: translateY(-50%);
    top: 50%;
    left: calc(100% - 22px);
    background-color: var(--input-toggle-handler-enabled);
}

.input-field.slider {
    display: flex;
    flex-direction: column;
}

.input-field.slider .slider-label {
    display: grid;
    grid-template-columns: 80% 20%;
    align-items: center;
}

.input-field.slider label {
    position: relative;
    left: unset;
    top: unset;
    transform: unset;
    transform-origin: unset;

    padding: 8px 0px;
}

.input-field.slider .slider-label p {
    margin: 0;
    text-align: center;
    font-size: 1.3rem;
}

.input-field.slider input {
    -webkit-appearance: none;
    width: 100%;
    background-color: transparent;
    box-shadow: none;
}

.input-field.slider input::-webkit-slider-thumb {
    -webkit-appearance: none;
    border-radius: 400px;
    width: 16px;
    height: 16px;
    background-color: var(--primary);
    box-shadow: 0px 2px 2px -1px rgba(50, 50, 50, 0.14),
                0px 1px 4px -1px rgba(50, 50, 50, 0.25);
    margin-top: -4px;
}

.input-field.slider input::-moz-range-thumb {
    border-radius: 400px;
    width: 16px;
    height: 16px;
    background-color: var(--primary);
    box-shadow: 0px 2px 2px -1px rgba(50, 50, 50, 0.14),
                0px 1px 4px -1px rgba(50, 50, 50, 0.25);
}

.input-field.slider input::-ms-thumb {
    border-radius: 400px;
    width: 16px;
    height: 16px;
    background-color: var(--primary);
    box-shadow: 0px 2px 2px -1px rgba(50, 50, 50, 0.14),
                0px 1px 4px -1px rgba(50, 50, 50, 0.25);
}

.input-field.slider input::-webkit-slider-runnable-track {
    width: 100%;
    background: var(--input-slider-track-background);
    height: 8px;
    border-radius: 10px;
}

.input-field.slider input:focus {
    outline: none;
}

.input-field.slider input::-ms-track {
    width: 100%;
    cursor: pointer;
  
    background: transparent; 
    border-color: transparent;
    color: transparent;
}

.number-arrow-input .input-wrapper {
    position: relative;
    display: grid;
    grid-template-columns: 70% 30%;
}

.number-arrow-input .input-wrapper input {
    position: relative;
    width: 100%;

    margin: 0;

    border: solid 1px var(--input-border-color);
    border-right: none;
    border-radius: 10px 0 0 10px;

    font-size: 1.2rem;
    font-weight: bold;
    text-align: center;

    background-color: var(--input-background-color);
    color: var(--text);
}

.number-arrow-input .input-wrapper .arrows-wrapper {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;

    width: 100%;

    background-color: var(--input-number-arrows-background);
    border: solid 1px var(--input-border-color);
    border-left: none;
    border-radius: 0 10px 10px 0;

    overflow: hidden;
}

.number-arrow-input .input-wrapper .arrows-wrapper .arrow {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    cursor: pointer;
    transition: unset;
}

.number-arrow-input .input-wrapper .arrows-wrapper .arrow:active {
    background-color: var(--input-number-arrows-active-background);
}

.number-arrow-input .input-wrapper .arrows-wrapper svg {
    color: var(--input-number-arrows-color);
    font-size: 1.1rem;
}

.team-score-input {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    margin: 10px 0;
    gap: 10px;
}

.team-score-input .number-arrow-input {
    width: 100%;
}

.number-arrow-input p {
    color: var(--text-light);
    font-size: .8rem;
    margin: 4px 0;
}

.loader.circular {
    animation: circular-loader-spin 3.38756823s linear infinite;
}

.loader.circular .loader-progress {
    position: relative;
    width: 35px;
    height: 35px;
    background-color: transparent;
    border-radius: 500px;
    border: solid 4px var(--loader-progress-background);
    border-top-color: var(--loader-progress-color);
    box-sizing: border-box;
    margin: 0 auto;
    animation: circular-loader-spin 1s cubic-bezier(0.76, 0, 0.24, 1) infinite;
}

.loader.linear {
    position: relative;
    width: 100%;
    height: 5px;
    background-color: var(--loader-progress-background);
    overflow: hidden;
}

.loader.linear .loader-progress {
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    background-color: var(--loader-progress-color);
    transform-origin: center;
    width: 10px;
    animation: linear-loader-infinite 1.2s ease-in-out infinite;
}

.nav.bottom {
    position: fixed;
    z-index: 100;

    bottom: 0;
    left: 0;

    width: 100%;
    height: 55px;

    background-color: var(--bottom-nav);
    color: var(--bottom-nav-text);
    border-top: solid 1px var(--card-border-color);

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;

    font-size: .6rem;
    text-align: center;

    /*
        TODO: experimental
     */
    backdrop-filter: blur(15px);

    padding-bottom: env(safe-area-inset-bottom);
}

.bottom-nav-spacing {
    position: relative;
    height: calc(55px + env(safe-area-inset-bottom));
}

.nav.bottom .nav-item.bottom {
    position: relative;

    text-align: center;

    width:20%;
    height: 95%;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.nav.bottom .nav-item.bottom p {
    margin: 5px 0;
}

.nav.bottom .nav-item.bottom .mdi-icon {
    width: 22px;
    height: 22px;
}

.nav.bottom .nav-item.bottom .plus {
    position: absolute;

    width: 42px;
    height: 42px;

    border-radius: 100px;

    box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.12);

    background-color: var(--bottom-plus);

    display: flex;
    align-items: center;
    justify-content: center;
}

.nav.bottom a {
    color: var(--bottom-nav-text);
    text-decoration: none; 
}

.react-tabs__tab-list {
    position: sticky;
    top: calc(54px + env(safe-area-inset-top));
    z-index: 100;

    height: 50px;

    width: 100%;

    display: flex;
    flex-direction: row;

    background-color: var(--primary);
    color: white;

    list-style-type: none;
    padding-left: 0;
    margin: 0;
    overflow-y: scroll;
}

.fixed-tab-spacing {
    position: relative;
    width: 1px;
    /*height: 50px;*/
}

.react-tabs__tab-list .react-tabs__tab {
    font-size: .8rem;
    text-transform: uppercase;
    margin: 0 5px;
    padding: 15px 10px;
    opacity: .6;
    box-shadow: inset 0px -5px 0px -6px rgba(255, 255, 255, 1);
    transition: all ease-in-out .125s;
    white-space: nowrap;
}

.react-tabs__tab.react-tabs__tab--selected {
    opacity: 1;
    box-shadow: inset 0px -5px 0px -1px rgba(255, 255, 255, 1);
}

.match-preview-link {
    text-decoration: none;
}

.match-preview {
    position: relative;
    width: calc(100% - 20px);
    margin: 8px 5px;

    display: flex;
    flex-direction: row;
    gap: 10px;
}

.match-preview > div {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.match-preview p {
    margin: 2px 0;
    font-size: .9rem;
    color: var(--text-light);
}

.match-preview .match-date {
    position: relative;
    width: 22%;
    text-align: center;
    border-right: solid 1px var(--card-border-color);
}

.match-preview .match-date p {
    font-size: .8rem;
}

.match-preview .match-date span {
    width: 100%;
    position: relative;
    display: inline-block;
}

.match-preview .match-teams {
    position: relative;
    width: 67%;
    box-sizing: border-box;
}

.match-preview .match-teams p.won,
.match-preview .match-score p.won {
    color: var(--text);
}

.match-preview .match-score {
    position: relative;
    width: 10%;
    text-align: right;
}

.match-preview .match-result {
    width: 10%;
    position: relative;
}

.match-preview .match-result .inner-result {
    position: absolute;
    display: block;
    width: 100%;
    padding-top: 100%;
    border-radius: 500px;
    transform: scale(.8);
}

.match-preview .match-result .inner-result p {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    color: white;
}

.inner-result.W {
    background-color: var(--form-won-color);
}

.inner-result.L {
    background-color: var(--form-lost-color);
}

.inner-result.D {
    background-color: var(--form-draw-color);
}

.fact-form.fact {
    display: grid;
    grid-template-columns: 1fr 4fr;
    gap: 10px;
}

.fact-form.extra-details {
    box-sizing: border-box;
    padding-left: 22%;
}

.match-header {
    position: relative;
    box-sizing: border-box;
    padding: 10px;

    background-color: var(--card-background-color);
}

.match-header .match-header-meta {
    border-bottom: solid 1px var(--card-border-color);
    padding-bottom: 15px;
}

.match-header .competition {
    font-size: .9rem;
    color: var(--text-light);
    margin: 0;
}

.match-header .teams {
    margin: 8px 0;
}

.match-header .score {
    display: grid;
    grid-template-columns: 16% 68% 16%;
    align-items: center;
    padding: 10px 25px;
    box-sizing: border-box;
}


.match-header .score .scores {
    text-align: center;
    font-size: 1.8rem;
    font-weight: 500;
}

.match-header .score .scores p {
    margin: 0;
    color: var(--text-light);
}

.match-header .score .scores p span {
    padding: 0 8px;
}

.match-header .score .scores p .won {
    color: var(--text);
}

.match-header .score .crest img {
    filter: drop-shadow(0px 2px 4px rgba(130, 149, 171, .5));
}

.match-header .date {
    font-size: .9rem;
    margin-bottom: 2px;
}

.match-details {
    overflow: hidden;
}

.match-facts {
    position: relative;

    padding: 15px 10px;
    box-sizing: border-box;

    display: flex;
    flex-direction: column-reverse;

    border-radius: 20px;
    box-shadow: var(--card-box-shadow);
    margin: var(--card-margin);
    background-color: var(--card-background-color);
}

.match-facts a {
    color: inherit;
    text-decoration: none;
}

.match-facts .fact {
    position: relative;
    display: flex;
    align-items: center;
    margin-bottom: 8px;
    min-height: 3rem;

    column-gap: 8px;
}

.match-facts .fact p {
    margin: 0;
    letter-spacing: -0.2px;
}

.match-facts .fact.home {
    flex-direction: row;
}

.match-facts .fact.away {
    flex-direction: row-reverse;
}

.match-facts .fact-icon {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 40px;

    box-sizing: border-box;
    row-gap: 6px;

    border-right: solid 1px var(--card-border-color);
}

.match-facts .fact.away .fact-icon {
    border-right: none;
    border-left: solid 1px var(--card-border-color);
}

.fact-icon img {
    position: relative;
    display: inline-block;
    width: 16px;
    margin-bottom: 2px;
}

.fact-icon .time {
    font-size: .7rem;
    color: var(--text-light);
    text-align: center;

    font-weight: 500;
}

.match-facts .fact .score {
    font-size: 1.7rem;
    letter-spacing: -0.8px;
    font-weight: 500;
    color: var(--text-light);
}

.match-facts.default .fact.home .score .home-score {
    color: var(--text);
}

.match-facts.default .fact.away .score .away-score {
    color: var(--text);
}

.match-facts .fact .details {
    box-sizing: border-box;
}

.match-facts .fact .details .extra {
    margin-top: 2px;
    font-size: .8rem;
    font-weight: 500;
    color: var(--text-light);
}

.match-facts .fact.away .details {
    text-align: right;
}

.crest {
    display: inline-flex;
    align-items: center;
    justify-content: center;
}

.crest svg {
    width: 48px;
    height: 48px;
    color: var(--default-crest-color);
}

.crest.international {
    position: relative;
    border: solid 2px white;
    border-radius: 500px;
    box-shadow: 0px 0px 1px 1px rgba(50, 50, 50, 0.19);
    box-sizing: border-box;
}

.crest .international-crest {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 500px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
}

.crest img {
    max-width: 100%;
    max-height: 64px;
}

.lineups {
    position: relative;
    width: 100%;
}

.lineups .lineup {
    position: relative;
    width: 100%;
    font-family: var(--third-font);
    font-size: 1.1rem;
    background-color: var(--lineup-field-main);
    color: var(--lineup-text-color);
    margin: 0;

    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}

.lineups .lineup .lineup-header {
    display: flex;
    flex-flow: row;
    justify-content: space-between;
    padding: 0 5px;
    box-sizing: border-box;
}

.lineups .lineup p {
    margin: 0;
    padding: 10px;
    box-sizing: border-box;
}

.lineups .lineup .positions {
    position: relative;
    display: block;
    width: 100%;
    padding-top: 395px;
    box-sizing: border-box;
    border: solid 3px var(--lineup-field-lines);
    background: var(--lineup-field-grass);
}

.lineups .lineup .positions .gk-box {
    position: absolute;
    display: block;
    width: 35%;
    padding-top: 15%;
    border: solid 3px var(--lineup-field-lines);
    top: -3px;
    left: 50%;
    transform: translateX(-50%);
}

.lineups .lineup:nth-child(2) .positions .gk-box {
    top: unset;
    bottom: -3px;
}

.lineups .lineup .positions .gk-box .small-box {
    position: absolute;
    display: block;
    width: 45%;
    padding-top: 15%;
    border: solid 3px var(--lineup-field-lines);
    top: -3px;
    left: 50%;
    transform: translateX(-50%);
}

.lineups .lineup:nth-child(2) .positions .gk-box .small-box {
    top: unset;
    bottom: -3px;
}

.lineups .lineup .positions .gk-box .half-circle {
    position: absolute;
    width: 25%;

    padding-top: 10%;

    border: solid 3px var(--lineup-field-lines);
    border-radius: 200px;
    border-top-left-radius: 0;
    border-top-right-radius: 0;

    transform: translateY(100%) translateX(-50%);
    bottom: -1px;
    left: 50%;
}

.lineups .lineup:nth-child(2) .positions .gk-box .half-circle {
    bottom: unset;
    top: 0;
    transform: translateY(-100%) translateX(-50%) rotate(180deg);
}

.lineups .lineup .positions .middle-circle {
    position: absolute;
    display: block;

    width: 20%;
    padding-top: 10%;

    border: solid 3px var(--lineup-field-lines);
    border-radius: 200px;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;

    bottom: -3px;
    left: 50%;
    transform: translateX(-50%);
}

.lineups .lineup:nth-child(2) .positions .middle-circle {
    transform: translateX(-50%) rotate(180deg);
    top: -3px;
    bottom: unset;
}

.lineups .lineup .positions .positions-container {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    box-sizing: border-box;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.lineups .lineup:nth-child(2) .positions .positions-container {
    flex-direction: column-reverse;
}

.lineups .lineup .positions .positions-container button.create-lineup {
    box-shadow: 0px 0px 7px -4px rgba(50,50,50,0.30),0px 0px 7px -2px rgba(50, 50, 50, 0.22);
}

.lineups .lineup:first-child .positions {
    border-bottom: none;
    margin-bottom: -1.5px;
}

.lineups .lineup:nth-child(2) {
    display: flex;
    flex-direction: column-reverse;
}

.lineups .lineup .positions .positions-container {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
}

.lineups .lineup .positions .positions-container .position-row {
    position: relative;
    width: 100%;
    padding-bottom: 25px;

    display: flex;
    flex-direction: row;
    justify-content: space-around;
}

.position-row .player-position-wrapper {
    position: relative;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    width: 100%;
}

.position-row .player-position-wrapper p.player-name {
    position: absolute;
    width: 100%;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%) translateY(100%);
    padding: 0;
    font-size: .8rem;
    text-align: center;
    color: var(--lineup-position-player-name-color);
}

.lineups .lineup .positions .positions-container .position-row .lineup-position-item {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 30px;
}

.lineups .lineup .positions .positions-container .position-row .lineup-position-item p.number {
    position: absolute;
    top: 25%;
    left: 50%;
    transform: translateX(-50%) translateY(0%);
    font-size: .8rem;
    width: 30px;
}

.lineup-position-item svg {
    filter: drop-shadow(0px 1px 1px rgba(0, 0, 0, 0.3));
    height: 30px;
    width: 100%;
}

.lineups .lineup .positions .positions-container .position-row .lineup-position-item .icons {
    position: absolute;
    width: 100%;
    height: 100%;

    top: 0;
    left: 0;

    z-index: 2;
}

.lineups .lineup .positions .positions-container .position-row .lineup-position-item .icons .fact-icon {
    position: absolute;

    width: 14px;
    height: 14px;
}

.lineups .lineup .positions .positions-container .position-row .lineup-position-item .icons .fact-icon.goal,
.lineups .lineup .positions .positions-container .position-row .lineup-position-item .icons .fact-icon.penalty_goal
{
    left: 100%;
    top: 100%;

    transform: translate(0, calc(-100% - 4px));
}

.lineups .lineup .positions .positions-container .position-row .lineup-position-item .icons .fact-icon.substitution {
    left: 0;
    top: 0;

    transform: translate(calc(-100% - 4px), 0);
}

.lineups .lineup .positions .positions-container .position-row .lineup-position-item .icons .fact-icon.double_yellow_card,
.lineups .lineup .positions .positions-container .position-row .lineup-position-item .icons .fact-icon.red_card {
    top: 0;
    left: calc(100% + 4px);
}

.lineup-template-card {
    position: relative;
    width: 100%;

    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;

    border-bottom: 1px solid var(--card-border-color);
}

.lineup-template-card p {
    margin: 9px 0;
}

.lineup-template-card .name-and-delete {
    display: grid;
    grid-template-columns: auto 32px;
    width: 100%;

    align-items: center;
}

.lineup-template-card .name-and-delete svg {
    color: var(--text-light);
}

.lineup-template-card .name-and-delete .template-name {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}

.lineup-template-card .template-name {
    font-size: 1.1rem;
    font-weight: 500;
}

.lineup-template-card .template-players {
    position: relative;
    width: 100%;
    font-size: .9rem;
    color: var(--text-light);
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}

.modal-wrapper {
    position: absolute;
    width: 0;
    height: 0;
}

.modal-container {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    height: calc(100% + 1px);
    overflow-y: scroll;
    overscroll-behavior-y: contain;
    z-index: 500;
    display: none;
}

.modal-container.enter {
    display: block;
}

.modal-container.enter-done {
    display: block;
}

.modal-container.exit {
    display: block;
}

.modal-container .modal-background {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;

    height: calc(100% + 1px);

    background-color: var(--modal-background);

    opacity: 0;

    transition: opacity .2s ease-in-out;
}

.modal-container .modal {
    position: absolute;
    
    width: 95%;
    max-height: 85%;
    overflow-y: scroll;
    overscroll-behavior-y: contain;
    
    padding: 20px 10px;
    box-sizing: border-box;

    left: 50%;
    top: 50%;
    
    border-radius: 20px;
    background-color: var(--modal-content-background);
    font-family: var(--default-fonts);
    font-size: 1rem;

    box-shadow: 0px 0px 5px -1px rgba(0, 0, 0, 0.2),0px 0px 10px -1px rgba(0, 0, 0, 0.1);

    transform: translateX(-50%) translateY(-50%) scale(1.5);

    opacity: 0;

    transition: all .2s cubic-bezier(0.075, 0.82, 0.165, 1);
}

.modal .modal-content {
    min-height: calc(100% + 1px);
}

.modal-container .modal .buttons-footer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.modal-container .modal h3 {
    margin: 9px 2px;
}

.modal-container.enter-active .modal {
    opacity: 1;
    transform: translateX(-50%) translateY(-50%) scale(1);
}

.modal-container.enter-done .modal {
    opacity: 1;
    transform: translateX(-50%) translateY(-50%) scale(1);
}

.modal-container.enter-active .modal-background {
    opacity: 1;
}

.modal-container.enter-done .modal-background {
    opacity: 1;
}

.modal-container.exit-active .modal-background {
    opacity: 0;
}

.chart.bars.horizontal {
    position: relative;
    width: 100%;

    padding: 0 10px;
    box-sizing: border-box;
}

.chart.bars.horizontal .bars-wrapper > p {
    position: absolute;
    z-index: 1;
    font-size: .8rem;
    font-weight: 600;
    display: inline-flex;
    margin: 0;
    align-items: center;
    height: 100%;
    color: black;
    opacity: .5;
}

.chart.bars.horizontal .bars-wrapper > p:first-child {
    left: 10px;
}

.chart.bars.horizontal .bars-wrapper > p:nth-child(2) {
    right: 10px;
}

.chart.bars.horizontal .labels {
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.chart.bars.horizontal .labels p {
    margin: 5px 2px;
    font-weight: 500;
    font-size: .8rem;
    color: var(--text-label);
}

.chart.bars.horizontal .bars-wrapper {
    position: relative;
    width: 100%;
    height: 28px;

    border-radius: 5px;

    background-color: var(--chart-bars-background);

    overflow: hidden;
}

.chart.bars.horizontal .bars-wrapper > div {
    min-width: 5px;
    max-width: calc(100% - 5px);

    transform: scaleX(0);
    animation: chart-bars-progress .45s cubic-bezier(0.16, 1, 0.3, 1);
    animation-fill-mode: forwards;
    transition: width cubic-bezier(0.16, 1, 0.3, 1) .45s;
}

.chart.bars.horizontal .bars-wrapper .bar-left {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    transform-origin: left;

    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;

    background-color: var(--chart-bars-left);
}

.chart.bars.horizontal .bars-wrapper .bar-right {
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;

    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;

    transform-origin: right;

    background-color: var(--chart-bars-right);
}

.h2h-teams {
    position: relative;
    padding: 10px;
    box-sizing: border-box;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.h2h-teams .h2h-team {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 45%;
    gap: 10px;
}

.h2h-teams .h2h-team .data {
    position: relative;
    width: calc(100% - 32px);
}

.h2h-teams .h2h-team p {
    margin: 0 5px;
    font-size: .8rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.h2h-teams .h2h-team p.team-wins {
    font-size: 1rem;
}

.h2h-teams .team-draws {
    position: relative;
    height: 100%;
    margin: 0;
    color: var(--text-light);
}

.h2h-teams .team-draws p {
    height: 100%;
}

.h2h-teams .h2h-team:first-child p.team-wins {
    color: var(--standard-home-team-color);
}

.h2h-teams .h2h-team:nth-child(3) p.team-wins {
    color: var(--standard-away-team-color);
}

.h2h-teams .h2h-team .crest img{
    max-width: 100%;
    max-height: 32px;
    position: relative;
    padding-top: 0;
}

.h2h-teams .h2h-team:nth-child(3) {
    flex-direction: row-reverse;
    text-align: right;
}

.match-top-scorers {
    position: relative;
    width: 100%;
    padding: 0 5px;
    box-sizing: border-box;
}

.match-top-scorers .player-stats-table .content-card {
    margin: unset;
    padding: unset;
    box-shadow: unset;
    border-radius: unset;
}

.player-stats-table {
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: column;
}

.player-stats-table .table-row {
    display: grid;
    padding: 0 10px;
    box-sizing: border-box;
    grid-template-columns: 90% 10%;
}

.player-stats-table .table-row .table-col {
    white-space: nowrap; 
    overflow: hidden;
    text-overflow: ellipsis; 
}

.player-stats-table .table-row .table-col.player.has-details p.value {
    margin-bottom: 5px;
}

.player-stats-table .table-row .table-col.player p.details {
    font-size: .9rem;
    margin-top: 0;
}

.player-stats-table .table-row.label {
    padding: 7px 10px;
}

.player-stats-table .table-row.with-positions {
    grid-template-columns: 1fr 6fr 2fr;
    gap: 10px;
}

.player-stats-table .table-row.with-positions.label .table-col:first-child {
    text-align: center;
}

.player-stats-table .table-row .table-col:last-child {
    text-align: right;
}

.player-stats-table a {
    display: block;
    border-bottom: solid 1px var(--card-border-color);
}

.player-stats-table .table-col.position {
    position: relative;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 500;
}

.player-stats-table .position .position-circle {
    position: absolute;
    z-index: 1;
    width: 24px;
    height: 24px;
    border-radius: 50px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: transparent;
}

.player-stats-table .item-0 .position .position-circle {
    background-color: gold;
}

.player-stats-table .item-1 .position .position-circle {
    background-color: silver;
}

.player-stats-table .item-2 .position .position-circle {
    background-color: orange;
}

.player-stats-table .table-row:not(.label) .table-col.amount {
    font-weight: 600;
}

.player-stats-table .position p {
    position: relative;
    z-index: 2;
}

.player-stats-table a:last-of-type {
    border-bottom: none;
}

.player-stats-table .button.inline-button,
.transfer-center-screen .button.inline-button {
    margin: 0;
    font-size: .9rem;
    padding: 0 10px;

    border: solid 1px var(--card-border-color);
}

.player-about .player-stats-wrapper .stats-table {
    background-color: var(--card-background-color);
    padding-bottom: 20px;
}

.match-meta {
    padding: 0 5px;
    box-sizing: border-box;
}

.header-crest {
    position: relative;
    width: 100%;
    padding: 10px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: -1px;
}

.header-crest .crest {
    width: 38px;
    filter: drop-shadow(0px 0px 3px white);
}

.team-recent-form {
    position: relative;
    width: 100%;
}

.team-recent-form .wrapper {
    padding: 10px 5px;
    box-sizing: border-box;
    border-radius: 10px;

    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
}

.team-recent-form .wrapper .match {
    position: relative;
    width: 100%;

    display: flex;
    flex-direction: column;
    justify-content: center;
}

.team-recent-form .wrapper .match .crest {
    margin-bottom: 10px;
}

.team-recent-form .wrapper .match img {
    position: relative;
    width: auto;
    max-width: 22px;
    max-height: 18px;
    height: 18px;
    margin: 6px 0;
}

.team-recent-form .wrapper .match .difficulty-bar {
    position: relative;
    width: 100%;
    height: 100px;
}

.difficulty-bar .bar {
    position: relative;
    height: 50%;
    width: calc(100% - 1px);
    left: 1px;

    display: flex;
    align-items: flex-start;
}

.difficulty-bar .bar .bar-inside {
    width: 100%;
    max-height: 100%;
    animation: form-bar-appear .28s ease-in-out;
}

.difficulty-bar .bar.won {
    align-items: flex-end;
}

.difficulty-bar .bar.draw,
.difficulty-bar .bar.draw.positive {
    align-items: flex-end;
}

.difficulty-bar .bar.draw.negative {
    top: 50%;
    align-items: unset;
}

.difficulty-bar .bar.lost {
    top: 50%;
}

.difficulty-bar .bar .bar-inside {
    min-height: 10%;
}

.difficulty-bar .bar.won .bar-inside {
    background-color: var(--form-won-color);
    transform-origin: bottom;
}

.difficulty-bar .bar.draw .bar-inside {
    background-color: var(--form-draw-color);
    height: 10%;
    transform-origin: bottom;
}

.difficulty-bar .bar.draw.negative .bar-inside {
    transform-origin: top;
}

.difficulty-bar .bar.lost .bar-inside {
    background-color: var(--form-lost-color);
    transform-origin: top;
}

.transfers {
    position: relative;
    padding: 0 5px;
    box-sizing: border-box;
}

.transfer-item {
    position: relative;
    width: 100%;
    padding: 10px 5px;
    box-sizing: border-box;

    display: grid;
    grid-template-rows: 30px 30px;

    border-bottom: solid 1px var(--card-border-color);
}

.transfer-item .player-data {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.transfer-item .player-data p,
.transfer-item .player-data h5 {
    margin: 0;
}

.transfer-item .transfer-data {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.transfer-item .transfer-data p {
    font-size: .9rem;
    margin: 0;
}

.transfer-item .transfer-data p span {
    margin-right: 10px;
}

.transfer-item .transfer-data p.fee-data {
    color: var(--transfer-fee-color);
}

.transfer-item .player-data .teams {
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.transfer-item .player-data .teams .to-arrow {
    position: relative;
}

.transfer-item .player-data .teams .crest {
    width: 18px;
    margin-left: 10px;
}

.to-arrow {
    border: 4px solid transparent;
    border-left: 5px solid var(--arrow-color);
    margin-left: 5px;
}

.buttons-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    position: relative;
}

.player-screen .transfers-wrapper {
    padding: 0 10px;
    box-sizing: border-box;
}

.list-card{
    position: relative;
    width: 100%;
    
    display: grid;
    grid-template-columns: 36px auto;
}

.list-card .crest {
    width: 32px;
    text-align: center;
}

.list-card h5,
.list-card p {
    margin: 3px 0;
}

.list-card p {
    font-size: .9rem;
}

.list-card h5 {
    font-weight: 400;
    font-size: 1rem;
}

.list-card p.date {
    color: var(--text-light);
}

.list-card p.fee,
.list-card p.loan-status {
    color: var(--transfer-fee-color);
}

.list-card .from-team {
    color: var(--text-light);
    font-size: .9rem;
}

.list-card p.loan-status {
    font-size: .9rem;
}

.list-card {
    padding: 3px 0;
    border-bottom: solid 1px var(--card-border-color);
}

.list-card .list-info {
    display: flex;
    flex-direction: column;
    padding: 7px 5px;
    box-sizing: border-box;
}

.list-card .list-info .row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.chartjs-chart {
    background-color: var(--chart-background);
}

.stats-table {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
}

.stats-table .stat {
    position: relative;
    width: calc(33% - 4px);
    max-width: calc(33% - 4px);
    text-align: center;

    margin: auto 2px;
}

.stats-table .stat.tappable {
    cursor: pointer;
    user-select: none;
}

.stats-table .stat.tappable:hover {
    background-color: var(--card-hover-background-color);
}

.stats-table .stat.tappable:active {
    background-color: var(--card-active-background-color);
    transform: scale(.9);
}

.stat p.label{
    text-transform: uppercase;
    font-size: .7rem;
    letter-spacing: -0.1px;
    color: var(--text-light);
}

.tenures-list .list-card p.duration {
    width: 100%;
    color: var(--text-light);
}

.tenures-list .list-card p .won {
    color: var(--form-won-color);
}

.tenures-list .list-card p .draw {
    color: var(--form-draw-color);
}

.tenures-list .list-card p .lost {
    color: var(--form-lost-color);
}

.tenures-list .list-card p.ppm {
    font-weight: 600;
}

.tenures-list .list-card p.stats {
    width: 100%;
    text-align: right;
    font-weight: 600;
}

.tenures-list .list-card p.stats span {
    margin-left: 16px;
}

.tenures-list {
    padding: 5px 10px;
    box-sizing: border-box;
}

.tenure-graph {
    box-sizing: border-box;
    padding: 0 10px;
}

.managers-list .card.card-category {
    cursor: pointer;
    grid-template-columns: 1fr 32px;
}

.manager-performance {
    position: relative;
    width: 100%;
    height: 150px;
    margin: 7px 0;
}

.manager-performance .legend {
    position: absolute;
    z-index: 2;
    top: calc(27px - .3rem);
    left: 3px;
    height: 120px;
    font-size: .9rem;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.manager-performance .legend p {
    height: 33%;
    margin: 0;
    display: flex;
    color: var(--text-light);
    font-weight: 600;
}

.manager-performance .wrapper {
    position: relative;
    width: 100%;
    height: 100%;
    background: var(--manager-performance-background);
    display: flex;
    flex-direction: row-reverse;
    align-items: flex-end;
    border-radius: 10px;
    overflow: hidden;
}

.manager-performance .wrapper .manager-bar-wrapper {
    position: relative;
    height: 100%;
    background-color: var(--manager-performance-bar-background);
    transition: .2s background-color ease-in-out;
}

.manager-performance .wrapper .manager-bar-wrapper:hover {
    background-color: var(--manager-performance-bar-hover-background);
}

.manager-performance .manager-bar-wrapper .club-icon {
    height: 30px;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
}

.manager-performance .club-icon .crest {
    min-width: 18px;
    width: 18px;
    max-width: 18px;
}

.manager-performance .manager-bar-wrapper .bar-container {
    height: 120px;
    position: relative;
}

.manager-tenures .manager-stats .manager-stats-header {
    background-color: var(--card-background-color);
    padding-bottom: 20px;
}

.manager-tenures .manager-stats .manager-stats-header > .list-card h5 {
    text-align: left;
}

.manager-tenures .manager-stats .manager-stats-header > .list-card p {
    margin: 0;
    color: var(--text-light);
    font-weight: 500;
    font-size: .8rem;
}

.manager-tenures .manager-stats .manager-stats-header > .list-card {
    cursor: pointer;
    box-sizing: border-box;
    padding: 12px 10px;
    border-bottom: solid 1px var(--card-border-color);
}

.manager-tenures .manager-stats .manager-stats-header > .list-card .list-info {
    border-bottom: none;
}

.manager-performance .wrapper .manager-bar {
    position: absolute;
    bottom: 0;
    width: 100%;
    background-color: var(--form-won-color);

    transform: scaleY(0);
    transform-origin: bottom;

    animation: form-bar-appear ease-in-out .5s;
    animation-fill-mode: forwards;

    transition: .2s background-color ease-in-out;
}

.manager-performance .wrapper .manager-bar-wrapper:hover .manager-bar {
    background-color: var(--manager-performance-bar-hover-color);
}

.manager-performance .wrapper .average {
    position: absolute;
    z-index: 20;

    width: 0%;
    border-bottom: dashed 1px var(--manager-performance-average-color);

    left: 0;

    animation: load-line .4s ease-in-out;
    animation-delay: .3s;
    animation-fill-mode: forwards;
}

.team-manager {
    position: relative;
    width: 100%;

    padding: 5px;
    box-sizing: border-box;

    z-index: 5;

    display: flex;
    flex-direction: row;
}

.team-manager .crest {
    margin: 0 8px;
    width: 42px;
}

.team-manager .team-colors.flag {
    border-radius: 15px;
    overflow: hidden;
    z-index: -1;
}

.team-manager h5 {
    font-weight: 500;
    font-size: 1rem;
    margin: 4px 0;
}

.team-manager p {
    margin: 2px 0;
}

.team-colors.flag {
    position: absolute;
    display: block;
    top: 0;
    left: 0;
    height: 100%;
    width: 70px;
}

.team-colors.flag > div {
    position: absolute;
    top: 0;
    height: 100%;
    box-shadow: -1px 0px 8px -3px rgba(50,50,50,0.30);
}

.team-colors.flag {
    --flag-1-width: 20px;
    --flag-2-width: 12px;
}

.team-colors.flag .team-color-1 {
    left: 0;
    width: var(--flag-1-width);
    z-index: 3;
}

.team-colors.flag .team-color-2 {
    left: var(--flag-1-width);
    width: var(--flag-2-width);
    z-index: 2;
}

.match-best-players {
    position: relative;
    width: 100%;

    padding: 4px 4px;
    margin-top: 15px;

    background-color: var(--match-best-players);

    box-sizing: border-box;
    border-radius: 200px;

    display: grid;
    grid-template-columns: 50% 50%;
}

.match-best-players .player {
    position: relative;
    height: 100%;

    display: flex;
    flex-direction: row;
    align-items: center;

    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    word-wrap: break-word;
}

.match-best-players a:last-child .player {
    flex-direction: row-reverse;
    text-align: right;
}

.match-best-players a:last-child .player p.name {
    float: right;
}

.match-best-players .player .rating-wrapper {
    position: relative;
    min-width: 40px;
    min-height: 40px;
    background-color: var(--match-best-players-rating-background);
    border-radius: 100px;
}

.match-best-players .player .rating-wrapper p.rating {
    position: absolute;
    top: 50%;
    left: 50%;
    margin: 0;
    text-align: center;
    transform: translate(-50%, -50%);
}

.match-best-players .player p {
    margin: 0 5px;
}

.match-best-players .player p.name {
    opacity: .8;
    font-size: .8rem;
}

p.rating {
    font-weight: 600;
    font-size: 1.2rem;
}

.sensor-load-more {
    position: relative;
    width: 1px;
    height: 1px;
}

.player-ratings-chart {
    position: relative;
    
    width: 100%;
    height: calc(165px + 1rem);

    padding: 10px;
    box-sizing: border-box;
    border-radius: 10px;

    display: grid;
    grid-template-columns: auto 20px;

    background-color: var(--player-ratings-chart-background);
}

.player-ratings-chart .ratings-legend {
    position: relative;

    width: 6px;
    height: calc(100% - 25px - 1rem);

    margin-top: 25px;
    margin-left: 4px;

    display: flex;
    flex-direction: column;
}

.player-ratings-chart .ratings-legend > div {
    position: relative;
    width: 100%;
    height: 100%;
}

.player-ratings-chart .ratings-legend > p {
    position: absolute;
    transform: translateX(9px);
    font-size: .8rem;
    font-weight: 600;
}

.player-ratings-chart .ratings-legend .score-9 {
    bottom: calc(80% - .8rem);
    color: var(--perfect-rating-color);
}

.player-ratings-chart .ratings-legend .score-6 {
    bottom: calc(16% - .8rem);
    color: var(--bad-rating-color);
}

.player-ratings-chart .ratings-legend .awful {
    height: 16%;
}

.player-ratings-chart .ratings-legend .bad {
    height: 24%;
}

.player-ratings-chart .ratings-legend .good,
.player-ratings-chart .ratings-legend .decent,
.player-ratings-chart .ratings-legend .perfect {
    height: 20%;
}

.player-ratings-chart .ratings {
    display: flex;
    flex-direction: row-reverse;
    align-items: flex-end;
}

.player-ratings-chart .ratings .player-rating-bar {
    position: relative;
    width: calc(10%);
    height: 100%;

    display: grid;
    grid-template-rows: 25px auto 1rem;
}

.player-ratings-chart .ratings .player-rating-bar .team {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding: 2px;
    box-sizing: border-box;
}

.player-ratings-chart .ratings .player-rating-bar .crest img {
    max-height: 18px;
    height: auto;
    max-width: 18px;
    width: auto;
}

.player-ratings-chart .ratings .player-rating-bar .bar {
    position: relative;
    height: 100%;
    margin-left: 2px;
}

.player-ratings-chart .ratings .player-rating-bar .bar-background {
    position: absolute;
    width: 100%;
    min-height: 5%;
    bottom: 0;
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;

    transform: scaleY(0);
    transform-origin: bottom;
    animation: form-bar-appear .25s ease-in-out;
    animation-fill-mode: forwards;
}

.player-ratings-chart .ratings .player-rating-bar .actual-rating .rating {
    font-size: .8rem;
    margin: 2px 0;
    text-align: center;
}

.player-transfer-value .value {
    color: var(--player-transfer-value-color);
}

.standing-screen {
    padding-bottom: 64px;
}

.standing-container {
    position: relative;
    width: 100%;
    box-sizing: border-box;
    padding: 5px;
    margin-bottom: 10px;
}

.standing-container .standing-positions-header {
    position: relative;
    width: 100%;

    display: grid;
    grid-template-columns: 28px auto 32px;
    align-items: center;
    gap: 8px;
}

.standing-container .standing-positions-header p {
    font-size: .9rem;
    font-weight: 600;
    color: var(--text-label);
    margin: 11px 0;
}

.standing-container .standing-positions-header p:first-child {
    text-align: center;
}

.standing-positions .standing-position {
    position: relative;
    width: 100%;
    box-sizing: border-box;
    padding: 0px 6px;

    display: grid;
    grid-template-columns: 28px 28px auto 32px;
    align-items: center;
    gap: 6px;
}

.standing-positions p {
    margin-top: .7rem;
    margin-bottom: .7rem;
}

.standing-positions .standing-position .position {
    margin-right: 5px;
}

.standing-positions .standing-position .team {
    margin-left: 6px;
    font-family: "Roboto Condensed";
    letter-spacing: -0.1px;
    font-size: .9rem;
}

.standing-positions .standing-position .points {
    text-align: center;
    font-size: .9rem;
}

.standing-position .position-number {
    position: relative;
    margin-right: 5px;
}

.standing-positions .standing-position .inner-position-number {
    position: absolute;
    width: 28px;
    height: 28px;
    border-radius: 100px;

    font-weight: 500;

    top: 50%;
    left: 50%;

    transform: translate(-50%, -50%);
}

.standing-position .inner-position-number p {
    position: absolute;
    margin: 0;
    text-align: center;
    font-size: .9rem;
    line-height: 0%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.standing-form {
    position: relative;
    width: 100%;
    padding: 5px;
    box-sizing: border-box;
}

.standing-form .fs-grid-row {
    gap: 10px;
}

.transfer-windows .card.card-category {
    display: grid;
    grid-template-columns: 44% 14% 21% 21%;
}

.transfer-windows .card.card-category p {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.transfer-windows .card-category .value,
.transfer-windows .card-category .year {
    text-align: center;
}

.transfer-windows .card-category .value {
    color: var(--transfer-fee-color);
}

.table-labels {
    display: grid;
    padding: 8px 15px;
    box-sizing: border-box;
    border-bottom: solid 1px var(--card-border-color);
}

.card.card-highlight {
    position: relative;

    width: 100%;

    padding: 12px;
    box-sizing: border-box;

    background-color: var(--card-highlight-background);

    box-shadow: 0px 0px 6px -3px rgba(117, 117, 117, 0.28),
                0px 0px 8px 2px rgba(117, 117, 117, 0.12);

    border-radius: 6px;    
}

.card.card-highlight h4 {
    margin: 7px 0;

    font-size: 1.4rem;
    font-weight: 500;
    text-align: left;
    text-overflow: break-word;
    word-wrap: break-word;
}

.card.card-highlight p {
    font-size: .7rem;
    margin: 0;
    color: var(--text-light);
}

.transfer-window-header {
    padding: 10px 5px;
    box-sizing: border-box;

    border-bottom: solid 1px var(--card-border-color);
}

.transfer-window-header .fs-grid-row {
    gap: 10px;
    grid-template-columns: repeat(2, 1fr);
}

.transfer-window-header .highlight-card-title {
    color: var(--transfer-fee-color);
}

.smooth-progress-bar-wrapper p.value-label {
    margin-top: 15px;
}

.smooth-progress-bar {
    position: relative;
    width: 100%;
    height: 12px;

    margin: 7px 0px;

    border-radius: 50px;
    background-color: var(--chart-bars-background);
}

.smooth-progress-bar .bar {
    position: relative;
    width: 100%;
    height: 100%;
    border-radius: 50px;
    overflow: hidden;
}

.smooth-progress-bar .bar > * {
    position: relative;
    height: 100%;
    max-width: 100%;
    left: 0;
    top: 0;

    display: inline-block;

    border-radius: 50px;

    vertical-align: top;
}

.smooth-progress-bar .left {
    background-color: var(--smooth-bars-left);
}

.smooth-progress-bar .right {
    background-color: var(--smooth-bars-right);
}

.transfer-objective {
    position: relative;
    width: 100%;

    display: grid;

    margin: 5px 0;

    padding: 8px;
    box-sizing: border-box;
}

.transfer-objective > .objective {
    position: relative;
    width: 100%;

    padding: 10px 7px;
    box-sizing: border-box;

    background-color: var(--transfer-objective-background);

    border: solid 1px var(--card-border-color);
    border-radius: 7px;
}

.transfer-window-screen h5 {
    text-align: left;
    color: var(--text-label-darker);
    text-transform: uppercase;
    margin: 18px 10px 8px 10px;
    font-weight: 500;
}

.transfer-objective > .icon {
    position: absolute;
    top: 8px;
    right: 12px;

    width: 20px;
    height: 20px;

    color: var(--more-button-color);
    z-index: 4;
}

.transfer-objective > * .fs-grid-row {
    align-items: center;
    justify-content: center;
}

.objective-buy .objective-players {
    position: relative;

    grid-area: bottom;
    
    padding: 5px 0px;
    box-sizing: border-box;

    border-top: dashed 1px var(--card-border-color);
}

.objective-players .player {
    display: grid;
    grid-template-columns: 20px calc(80% - 20px - 18px) 20% 18px;
    align-items: center;

    padding: 3px 0;
    box-sizing: border-box;
}

.objective-players .player svg {
    color: var(--more-button-color);
    width: 1rem;
    height: 1rem;
}

.objective-players .player .name {
    margin: 0;
    font-size: .7rem;
    margin-left: 8px;

    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.objective-players .player .monetary.value {
    margin: 0;
    font-size: .8rem;
    text-align: center;
}

.objective-players .add-player {
    display: flex;
    flex-direction: row;
    justify-content: center;

    position: relative;
    width: 100%;

    padding-top: 3px;
}

.transfer-objective .objective-amount p{
    margin: 0;
    text-align: center;
}

.objective-buy .position {
    position: relative;
    width: 31px;
    height: 31px;
    border-radius: 500px;
}

.objective-buy .position p {
    margin: 0;
    position: absolute;
    
    top: 50%;
    left: 50%;

    font-size: .8rem;
    font-weight: 500;

    transform: translate(-50%, -50%);
}

.objective-buy .smooth-progress-bar {
    margin-bottom: 0;
}

.objective-sell p.player {
    margin: 0;
    font-size: .9rem; 
}

.objective-sell .transfer-objective-data {
    grid-template-columns: 100%;
}

.transfer-objective-data {
    display: grid;
    grid-template-columns:  60% 40%;
    align-items: center;
}

.transfer-objective-data .player {
    font-size: .9rem;
    margin: 4px 2px;
}

.transfer-objective-data .tag.negative,
.objective-sell .transfer-objective-data .tag.positive {
    background-color: var(--tag-negative-background);
    color: var(--tag-negative-color);
}

.transfer-objective-data .tag.positive,
.objective-sell .transfer-objective-data .tag.negative {
    background-color: var(--tag-positive-background);
    color: var(--tag-positive-color);
}

.transfer-objective-data > * {
    padding: 0 5px;
    box-sizing: border-box;
}

.transfer-objective-data .monetary.value {
    font-size: .9rem;
    margin: 4px 0;
}

.monetary.value {
    color: var(--transfer-fee-color);
}

.player-priority {
    position: relative;
    width: 20px;
    height: 20px;
    border-radius: 500px;
}

.player.inactive {
    color: var(--inactive-priority-color);
    text-decoration: line-through;
}

.player.inactive .monetary.value {
    color: var(--inactive-priority-color);
}

.player-priority p {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: white;
    margin: 0;
    font-size: .6rem;
}

.tag {
    display: inline-block;
    position: relative;
    color: var(--standard-tag-color);
    background-color: var(--standard-tag-background);

    padding: 3px 8px;
    box-sizing: border-box;

    border-radius: 500px;

    margin: 0;
}

.tag p {
    margin: 0;
    font-size: .8rem;
}

.scrollable-tag-line {
    min-width: 100%;
}

.scrollable-tag-line .tag-line-tags {
    position: relative;
    overflow-x: scroll;
    min-width: 100%;

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;

    padding: 12px 2px;
    box-sizing: border-box;

    border-bottom: solid 1px var(--card-border-color);
}

.scrollable-tag-line .tag-line-tags .tag {
    font-size: 1rem;

    margin: 0 3px;
    padding: 5px 10px;

    white-space: nowrap;

    outline: none;
    border: none;

    background-color: var(--secondary);
    box-shadow: 0px 2px 5px 1px var(--scrollable-tags-box-shadow-color);
}

.scrollable-tag-line > p {
    margin-top: 10px;
    margin-bottom: 0;
    padding: 0 10px;
    box-sizing: border-box;
}

.tab-sheet-select {
    position: fixed;
    width: 100%;
    height: 100%;

    top: 0;
    left: 0;

    z-index: 1000;

    display: none;

    padding-bottom: env(safe-area-inset-bottom);
    box-sizing: border-box;
}

.tab-sheet-select-enter-active {
    display: block;
}

.tab-sheet-select-enter-done {
    display: block;
}

.tab-sheet-select-exit {
    display: block;
}

.tab-sheet-select .backdrop {
    position: fixed;
    height: 100%;
    width: 100%;

    background-color: var(--modal-background);

    transition: opacity ease-in-out 200ms, backdrop-filter ease-in-out 200ms;
}

.tab-sheet-select .backdrop.sheet-backdrop-enter {
    opacity: 0;
    transition: opacity ease-in-out 200ms;
}

.tab-sheet-select .backdrop.sheet-backdrop-enter-done {
    opacity: 1;
}

.tab-sheet-select .backdrop.sheet-backdrop-exit {
    opacity: 1;
    transition: opacity ease-in-out 200ms;
}

.tab-sheet-select .backdrop.sheet-backdrop-exit-done {
    opacity: 0;
}

.tab-sheet-select .sheet {
    position: absolute;
    bottom: 0;
    left: 0;

    padding-bottom: env(safe-area-inset-bottom);

    width: 100%;
    min-height: 40px;
    max-height: 90%;

    display: flex;
    flex-direction: column;

    background: var(--tab-sheet-background);

    font-family: var(--default-fonts);

    border-top-left-radius: 20px;
    border-top-right-radius: 20px;

    box-shadow: 0px 0px 6px 0px rgba(59, 59, 59, 0.19);

    transition: transform cubic-bezier(0.25, 1, 0.5, 1) 200ms;
}

.tab-sheet-select .sheet .sheet-header {
    position: relative;
    width: 100%;

    padding: 15px 30px;
    box-sizing: border-box;

    border-bottom: solid 1px var(--card-border-color);
}

.tab-sheet-select .sheet .sheet-header .close-button {
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translateY(-50%);
    padding: 4px;
}

.tab-sheet-select .sheet .sheet-header h4 {
    margin: 0;
}

.tab-sheet-select .sheet .content {
    position: relative;
    width: 100%;
    height: 100%;

    overflow-y: scroll;

    display: inline-block;
}

.tab-sheet-select .sheet.sheet-content-enter {
    transform: translateY(100%);
    transition: transform ease-in-out 200ms;
}

.tab-sheet-select .sheet.sheet-content-enter-done {
    transform: translateY(0%);
}

.tab-sheet-select .sheet.sheet-content-exit {
    transform: translateY(0%);
    transition: transform ease-in-out 200ms;
}

.tab-sheet-select .sheet.sheet-content-exit-done {
    transform: translateY(100%);
}

.tab-sheet-select .sheet .select-sheet-tab {
    position: relative;
    display: flex;

    flex-direction: row;
    align-items: center;
    justify-content: flex-start;

    padding: 12px 10px;
    box-sizing: border-box;

    border-bottom: solid 1px var(--card-border-color);

    transition: background-color .2s ease-in-out;
}

.tab-sheet-select .sheet .select-sheet-tab:hover {
    background-color: var(--card-hover-background-color);
}

.tab-sheet-select .sheet .select-sheet-tab p {
    margin: 0;
    margin-left: 10px;
}

.tab-sheet-select .sheet .select-sheet-tab svg {
    color: var(--sheet-tab-icon-color);
}

.small-create-button {
    position: relative;
    width: 22px;
    height: 22px;

    background-color: var(--small-create-button-background);
    color: var(--small-create-button-color);

    border-radius: 200px;

    display: flex;
    align-items: center;
    justify-content: center;
}

.small-create-button svg {
    width: 16px;
    height: 16px;
}

.small-create-button-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
}

.small-create-button-container p {
    margin: 0;
    margin-left: 7px;
    font-size: .8rem;
    color: var(--small-create-button-label);
}

.close-button {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    width: 20px;
    height: 20px;

    border-radius: 500px;

    cursor: pointer;

    color: var(--close-button-color);
    background: var(--close-button-background);
}

.close-button svg {
    width: 14px;
    height: 14px;
}

.feature-description {
    position: relative;
    width: 100%;
    margin: 10px 0;
    text-align: center;

    padding: 5px 15px;
    box-sizing: border-box;
}

.feature-description svg {
    width: 34px;
    height: 34px;
    color: var(--feature-preview-icon-color);
}

.feature-description h4 {
    font-size: 1.2rem;
    color: var(--feature-description-title);
}

.feature-description p {
    color: var(--feature-description-text);
    font-size: .9rem;
    line-height: 1.1rem;
}

.competition-group-header {
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;

    height: 36px;
    width: 100%;

    padding: 0 5px;
    margin-top: 6px;
    box-sizing: border-box;

    font-weight: bold;
}

.country-container {
    position: relative;
    width: 22%;

    display: flex;
    align-items: center;
    justify-content: center;
}

.country {
    width: 18px;
    height: 18px;
}

.country-container .crest.international {
    border: none;
}

.competition-group-header p {
    margin: 0;
    font-size: .8rem;
    color: var(--text-light);
}

.league-country {
    position: relative;
    display: flex;

    width: 100%;

    align-items: center;
    justify-content: flex-start;

    padding: 0px 5px;
    box-sizing: border-box;

    background-color: var(--card-hover-background-color);
    border-radius: 10px;
}

.league-country .country-container {
    width: 48px;
}

.league-country .country-container .country {
    width: 26px;
    height: 26px;
}

.league {
    position: relative;
    width: 100%;

    padding-left: 48px;
    box-sizing: border-box;

    font-size: .9rem;
}

.league.last {
    border-bottom: solid 1px var(--card-border-color);
}

.featured-match {
    border-radius: 10px;

    margin: 10px 7px;
    padding: 20px 15px;
    box-sizing: border-box;
    background-color: var(--card-hover-background-color);
}


.featured-match p {
    margin: 0;
    margin-bottom: 8px;
    font-size: .9rem;
}

.featured-match .scores {
    display: grid;
    grid-template-columns: 36px auto 32px;
    align-items: center;
    justify-content: space-around;
    height: 46px;
}

.featured-match .scores p {
    display: inline;
    font-size: 1.3rem;
    font-weight: bold;
    margin: 0 5px;
    color: var(--text-light);
}

.featured-match .scores p.winner {
    color: var(--text);
}

.homescreen-header {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    width: 100%;
    min-height: 85vh;
    padding: 60px 10px;
    box-sizing: border-box;

    background-color: var(--homescreen-header-background);
    background-image: url("/public/resources/images/promo/doodle.png");
    background-repeat: repeat;
    color: white;
}

.homescreen-header .content {
    max-width: 1200px;
    margin: 0 auto;
}

.homescreen-header .content .images-wrapper img {
    border-radius: 10px;
}

.homescreen-header .button-signin {
    margin: 20px 0;
    font-size: 1.3rem;
}

.homescreen-header p {
    margin: 30px 0 0 0;
    font-size: 1.1rem;
}

.fs-container {
    position: relative;
    width: 100%;
    box-sizing: border-box;
    padding: 4px 0;
    overflow: hidden;
}

.fs-container .fs-title {
    font-size: 1.5rem;
    font-weight: 900;
    padding: 0 10px;
    font-family: "Archivo", "Roboto", Arial, sans-serif;
}

.fs-container .fs-subtitle {
    padding: 0 10px;
}

.fs-container .fs-image-container {
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.fs-container .fs-image-container .fs-image {
    z-index: 3;
}

.gradient-background {
    position: absolute;
    bottom: 20%;
    left: 0;

    width: 100%;
    height: 150px;
    z-index: 0;

    transform: scaleX(2) rotate(-10deg);
    transform-origin: center;

    background-image: var(--homescreen-gradient);
}

.button-signin.bottom {
    background-image: var(--homescreen-gradient);
    position: fixed;
    bottom: 0;
    left: 0;
    z-index: 20;
    border: none;
    width: 50%;
    left: 50%;
    transform: translateX(-50%);
    transform-origin: center;
    text-align: center;
    box-shadow: 0px 0px 44px -11px rgba(0,0,0,0.33);
    transition: transform .3s cubic-bezier(0.34, 1.56, 0.64, 1), opacity .3s cubic-bezier(0.16, 1, 0.3, 1);;
}

.button-signin.bottom.show {
    transform: translateX(-50%) scale(1);
    opacity: 1;
}

.button-signin.bottom.hide {
    transform: translateX(-50%) scale(0);
    opacity: 0;
}

.legal-disclaimer {
    padding: 60px 10px;
    color: var(--text-light);
}

.knockout-rounds-tree {
    position: relative;

    width: 100%;

    display: flex;
    flex-direction: column;
    justify-content: space-around;
}

.knockout-row {
    display: flex;
    flex-direction: row;
    justify-content: space-around;

    margin: 10px 0;
}

.knockout-row .knockout-square {
    position: relative;

    width: 23%;
    min-height: 40px;

    box-sizing: border-box;
    padding: 8px 3px 8px 3px;

    background-color: var(--knockout-square-background);

    box-shadow: var(--card-box-shadow);
    border-radius: 7px;
}

.knockout-row .knockout-square .team-names-abbr {
    font-size: .8rem;
    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: -0.4px;
    color: var(--text-light);
}

.knockout-row .knockout-square .team-names-abbr p {
    display: flex;
    flex-direction: row;
    justify-content: space-around;

    margin: 5px 2px;
}

.knockout-row .knockout-square .team-names-abbr .winner {
    color: var(--text);
}

.knockout-square.final {
    border: solid 2px var(--knockout-square-final-border);
    box-shadow: 0px 0px 5px -3px rgba(242, 177, 66, 1),0px 0px 11px 0px rgba(242, 177, 66, 0.74);
}

.knockout-square .crests {
    display: flex;
    flex-direction: row;

    justify-content: space-around;
}

.knockout-square .crests .crest {
    width: 24px;
    height: 24px;
}

.knockout-square .scores {
    font-size: 1.2rem;
    font-weight: bold;
}

.knockout-square .scores p {
    margin: 0 auto;
    text-align: center;
    color: var(--text-light);
}

.knockout-square .scores p span {
    margin: 0 4px;
}

.knockout-square .scores p span.winner {
    color: var(--text);
}

.transfer-history {
    display: flex;
    flex-direction: row;
    width: calc(100% - 20px);

    margin: 5px 10px;

    height: 160px;

    background-color: var(--player-ratings-chart-background);
}

.transfer-history-bar {
    position: relative;
    min-width: 1px;
}

.transfer-history-bar.last:not(.eoc) {
    min-width: 19px;
}

.transfer-history-bar:nth-child(odd) {
    background-color: var(--manager-performance-bar-background);
}

.transfer-history-bar-header {
    position: relative;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.transfer-history-bar-header .crest img {
    max-height: 18px;
    max-width: 18px;
    width: auto;
}

.transfer-history-bar-body {
    position: relative;

    height: calc(100% - 40px);
}

.transfer-history-bar-body svg {
    position: absolute;
    transform: translateY(-4px);
    z-index: 1;
}

.transfer-history-bar-marker {
    position: absolute;

    left: 0;
    width: 3px;
    height: 3px;
    border: solid 3px var(--transfer-fee-color);
    border-radius: 100px;

    z-index: 2;

    transform: translateX(-50%);

    background-color: white;
}

.inline-filter {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    gap: 10px;
    padding: 10px;
    box-sizing: border-box;

    background-color: var(--card-background-color);
    border-bottom: solid 1px var(--card-border-color);
}

.inline-filter .select-toggle {
    cursor: pointer;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
    background-color: var(--body-background-color);
    border-radius: 20px;
    padding: 8px 20px;
    max-width: calc(50% - 10px);
}

.inline-filter .select-toggle p {
    margin: 0;
    font-weight: 500;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}

.horizontal-scrollable {
    position: relative;
    overflow-x: scroll;
    overflow-y: hidden;
    min-width: 100%;

    white-space: nowrap;
}

.horizontal-scrollable > * {
    display: inline-block;
}

.overview-stats {
    position: relative;
}

.overview-stats .stat-card {
    position: relative;

    display: inline-flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-end;

    width: 140px;
    height: 100px;

    border-radius: 5px;

    margin: 15px 11px;
    padding: 7px 10px;

    box-sizing: border-box;

    background-color: var(--ternary);

    overflow: hidden;

    box-shadow: 0px 2px 10px -1px rgba(0, 0, 0, 0.2),0px 2px 3px -1px rgba(0, 0, 0, 0.1),2px 0px 3px -1px rgba(0, 0, 0, 0.1),-2px 0px 3px -1px rgba(0, 0, 0, 0.1);

}

.overview-stats .stat-card p {
    margin: 5px 2px;
}

.overview-stats .stat-card p:first-child {
    font-size: 1.4rem;
    font-weight: bold;

    color: var(--text-ternary);
}

.overview-stats .stat-card p:nth-child(2) {
    font-size: .9rem;
    text-transform: uppercase;
    font-weight: bold;

    color: var(--text-ternary-darker);
}

.overview-stats .stat-card .stat-icon {
    position: absolute;;

    top: -18px;
    right: -18px;
}

.overview-stats .stat-card .stat-icon svg {
    width: 90px;
    height: 90px;
    color: white;
    opacity: .3;
}

.save-picker p.title {
    margin-left: 15px;
    font-size: .8rem;
    font-weight: 500;
    text-transform: uppercase;
    color: var(--text-label-darker);
}

.card-category.save-games {
    display: grid;
    grid-template-columns: 32px 1fr 32px;
    align-items: center;
}

.card-category.save-games.pinned {
    background-color: var(--save-card-pinned-background);
    color: var(--save-card-pinned-color);
}

.key-value-table {
    position: relative;
}

.key-value-table .key-value-item {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    gap: 15px;

    border-bottom: solid 1px var(--card-border-color);
}

.key-value-table .key-value-item .key-value-key {
    font-size: .9rem;
    font-weight: 500;
    text-transform: uppercase;

    color: var(--text-light);
}

.sheet .content .key-value-table {
    padding: 0 10px;
    box-sizing: border-box;
}

.sheet .content .key-value-table .key-value-item:last-child {

}

body.dark .card-category.save-games.pinned {
    background-color: transparent;
}

body.dark .card-category.save-games.pinned::after {
    content: '';

    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    width: 4px;

    background-color: var(--save-card-pinned-background);
}

.tab-sheet-select.save-edit .content {
    padding: 0 15px;
    box-sizing: border-box;
}

.league-winners .content-card {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-around;
    text-align: center;
}

.league-winners .crest {
    width: 32px;
}

.league-winners .league-winner p {
    font-size: .8rem;
    margin: 4px;
}

.league-winners .league-winner p.winner-label {
    color: var(--text-label);
    margin-bottom: 10px;
}

.league-past-winners .league-past-winner {
    display: flex;
    flex-direction: column;

    padding-top: 11px;
}

.league-past-winners .league-past-winner-header {
    margin-bottom: 6px;
}

.league-past-winners .league-past-winner-header a {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    padding-bottom: 6px;

    border-bottom: solid 1px var(--card-border-color);
}

.league-past-winners .league-past-winner-header p {
    margin: 0;
    font-weight: bold;
}


.league-past-winners .league-past-winner-team {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: 15px;
}

.league-past-winners .league-past-winner-team .crest {
    width: 35px;
}

@keyframes header-title-appear {
    from {
        opacity: 0;
        transform: translateY(10px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}

@keyframes header-title-disappear {
    from {
        opacity: 0;
        transform: translateY(-10px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}

@keyframes fab-appear {
    from {
        transform: scale(0);
    }
    to {
        transfrom: scale(1);
    }
}

@keyframes form-bar-appear {
    from {
        transform: scaleY(0);
    } to {
        transform: scaleY(1);
    }
}

@keyframes chart-bars-progress {
    from {
        transform: scaleX(0);
    }
    to {
        transform: scaleX(1);
    }
}

@keyframes load-line {
    from {
        width: 0%;
    } to {
        width: 100%;
    }
}

@keyframes linear-loader-infinite {
    0% {
        left: 0;
        transform: translateX(-100%) scaleX(1);
    }
    50% {
        transform: scaleX(20);
    }
    100% {
        left: 100%;
        transform: translateX(0) scaleX(1);
    }
}

@keyframes exit-to-left {
    from {
        transform: translateX(-100%);
    }
    to {
        transform: translateX(-200%);
    }
}

@keyframes enter-to-left {
    from {
        transform: translateX(100%);
    }
    to {
        transform: translateX(0);
    }
}

@keyframes exit-to-right {
    from {
        transform: translateX(-100%);
    }
    to {
        transform: translateX(0);
    }
}

@keyframes enter-to-right {
    from {
        transform: translateX(-100%);
    }
    to {
        transform: translateX(0);
    }
}

@keyframes circular-loader-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

@media screen and ( min-width: 1000px ) {
    .side-nav-wrapper .side-nav {
        width: 20%;
        max-width: 350px;
    }

    .side-nav-wrapper .side-nav .side-nav-content {

    }

    .react-tabs ul.react-tabs__tab-list {
        justify-content: center;
    }

    .react-tabs ul li {
        cursor: pointer;
    }

    .react-tabs ul li:hover {
        background-color: rgba(0, 0, 0, 0.1);
    }
}