.tab-sheet-select .sheet .select-sheet-tab-large {
    position: relative;
    display: flex;

    flex-direction: row;
    align-items: center;
    justify-content: flex-start;

    padding: 18px 10px;
    box-sizing: border-box;

    border-bottom: solid 1px var(--card-border-color);

    transition: background-color .2s ease-in-out;
}

.tab-sheet-select .sheet .select-sheet-tab-large:hover {
    background-color: var(--card-hover-background-color);
}

.tab-sheet-select .sheet .select-sheet-tab-large p {
    margin: 0;
    font-weight: 500;
}

@media screen and ( min-width: 1000px ) {
    .tab-sheet-select .sheet {
        width: 500px;
        max-width: 50%;
        left: 50%;
    }

    .tab-sheet-select .sheet.sheet-content-enter {
        transform: translate(-50%, 100%);
    }

    .tab-sheet-select .sheet.sheet-content-enter-done {
        transform: translate(-50%, 0);
    }

    .tab-sheet-select .sheet.sheet-content-exit {
        transform: translate(-50%, 0);
    }

    .tab-sheet-select .sheet.sheet-content-exit-done {
        transform: translate(-50%, 100%);
    }
}