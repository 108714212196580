.bottom-nav-app-drawer {
    display: none;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;

    row-gap: 10px;

    position: fixed;
    z-index: 50;

    right: 12px;
    bottom: 65px;

    padding-bottom: env(safe-area-inset-bottom);
}

.bottom-nav-app-drawer.bottom-nav-app-drawer-enter {
    display: flex;

    opacity: 0;
}

.bottom-nav-app-drawer.bottom-nav-app-drawer-enter-active {
    display: flex;

    opacity: 1;

    transition: all .25s cubic-bezier(0.16, 1, 0.3, 1);
}


.bottom-nav-app-drawer.bottom-nav-app-drawer-enter-done {
    display: flex;

    opacity: 1;
}

.bottom-nav-app-drawer.bottom-nav-app-drawer-exit {
    display: flex;

    opacity: 1;
}

.bottom-nav-app-drawer.bottom-nav-app-drawer-exit-active {
    display: flex;

    opacity: 0;

    transition: all .25s cubic-bezier(0.16, 1, 0.3, 1);
}

.bottom-nav-app-drawer.bottom-nav-app-drawer-exit-done {
    display: none;

    opacity: 0;
}

.bottom-nav-app-drawer .nav-item.bottom {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    width: 54px;
    height: 54px;

    background-color: var(--bottom-nav-app-drawer-background);
    color: var(--bottom-nav-app-drawer-color);

    border-radius: 500px;

    transform: translateY(150%) scale(.3);
    transform-origin: center;

    box-shadow: 0px 0px 14px -8px rgba(50,50,50,0.30),
                0px 0px 4px -2px rgba(50,50,50,0.30),
                0px 0px 12px -3px rgba(50, 50, 50, 0.11);
}

.bottom-nav-app-drawer .nav-item.bottom.enter {
    transform: translateY(150%) scale(.3);
}

.bottom-nav-app-drawer .nav-item.bottom.enter-active {
    transform: translateY(0) scale(1);

    transition: all .25s cubic-bezier(0.16, 1, 0.3, 1);
}

.bottom-nav-app-drawer .nav-item.bottom.enter-done {
    transform: translateY(0) scale(1);
}

.bottom-nav-app-drawer .nav-item.bottom.exit {
    transform: translateY(0) scale(1);
}

.bottom-nav-app-drawer .nav-item.bottom.exit-active {
    transform: translateY(0) scale(1);
}

.bottom-nav-app-drawer .nav-item.bottom.exit-done {
    transform: translateY(150%) scale(.3);
}