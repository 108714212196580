.labeled-select {
    margin-bottom: 10px;
}

.labeled-select-label p {
    color: var(--text-label);
    font-size: .9rem;
    font-weight: bold;
}

.labeled-select-options {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
}

.labeled-select-option {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: 6px;

    flex: 1 1 0;

    position: relative;
    border: solid 1px var(--card-border-color);
    border-radius: 8px;
    width: 0;

    padding: 8px;
    box-sizing: border-box;

    cursor: pointer;

    transition: all .2s ease-in-out;
    user-select: none;
}

.labeled-select-option:hover {
    box-shadow: var(--card-box-shadow)
}

.labeled-select-option p {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    font-size: .9rem;

    margin: 0;
}

.labeled-select-option.selected {
    background-color: var(--primary-lighter);
    border: solid 1px var(--primary);
}

.labeled-select.match-fact-type .labeled-select-options {
    overflow-x: scroll;
}

.labeled-select.match-fact-type .labeled-select-option {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 6px;

    min-width: 42px;
}

.labeled-select.match-fact-type .fact-icon,
.labeled-select.match-fact-type .fact-icon img {
    width: 26px;
    height: 26px;
    margin-bottom: 0;
}

.labeled-select.match-fact-side .crest {
    width: 34px;
    height: 34px;
}