@media screen and ( min-width: 1000px ) {
    .content-wrapper {
        position: relative;
        display: block;

        width: 1200px;
        max-width: 90%;

        margin: 0 auto;
    }
}