@font-face {
    font-family: "Archivo";
    font-weight: 400;
    src: url(./../fonts/Archivo-Regular.ttf);
}

@font-face {
    font-family: "Archivo";
    font-weight: 700;
    src: url(./../fonts/Archivo-Bold.ttf);
}

@font-face {
    font-family: "Archivo";
    font-weight: 900;
    src: url(./../fonts/Archivo-Black.ttf);
}

@font-face {
    font-family: "Roboto";
    font-weight: 400;
    src: url(./../fonts/Roboto-Regular.ttf);
}

@font-face {
    font-family: "Roboto";
    font-weight: 300;
    src: url(./../fonts/Roboto-Light.ttf);
}

@font-face {
    font-family: "Roboto";
    font-weight: 500;
    src: url(./../fonts/Roboto-Medium.ttf);
}

@font-face {
    font-family: "Roboto";
    font-weight: 700;
    src: url(./../fonts/Roboto-Bold.ttf);
}

@font-face {
    font-family: "Roboto Condensed";
    font-weight: 400;
    src: url(./../fonts/RobotoCondensed-Regular.ttf);
}

@font-face {
    font-family: "Roboto Condensed";
    font-weight: 300;
    src: url(./../fonts/RobotoCondensed-Light.ttf);
}

@font-face {
    font-family: "Roboto Condensed";
    font-weight: 700;
    src: url(./../fonts/RobotoCondensed-Bold.ttf);
}
