@media screen and (min-width: 1000px) {
    .knockout-rounds-tree {
        flex-direction: row;
    }

    .knockout-rounds-tree .knockout-row {
        flex-direction: column;
        align-items: center;
        gap: 70px;
    }

    .knockout-rounds-tree .knockout-row .knockout-square {
        width: 100%;
        cursor: pointer;
        transition: all .2s ease-in-out;
    }

    .knockout-rounds-tree .knockout-row .knockout-square:not(.final):hover {
        box-shadow: 0px 2px 8px 0px rgba(50, 50, 50, 0.21);
    }

    .knockout-rounds-tree .knockout-row .knockout-square.final:hover {
        box-shadow: 0px 0px 11px -3px rgba(242, 177, 66, 1),0px 0px 11px 5px rgba(242, 177, 66, 1);
    }

    .knockout-rounds-tree .knockout-row .knockout-square .crests {
        gap: 8px;
    }

    .knockout-rounds-tree .knockout-row .knockout-square .crests .crest {
        width: 36px;
        height: unset;
        max-height: 36px;
    }
}