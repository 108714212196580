.card.card-category {
    display: grid;
    align-items: center;
}

.content-card {
    position: relative;
    margin: var(--card-margin);
    padding: var(--card-padding);
    border-radius: 20px;
    box-shadow: var(--card-box-shadow);
    background-color: var(--card-background-color);
    box-sizing: border-box;
}

.lineup-template-card {
    cursor: pointer;

    padding: 0 10px;
    box-sizing: border-box;
}

.lineup-template-card:hover {
    background-color: var(--card-hover-background-color);
}

.card.card-category {
    transition: background-color .2s ease-in-out;
}

.card.card-category:hover {
    background-color: var(--card-hover-background-color);
}

.save-games.card.card-category {
    cursor: pointer;
}

.card-content-wrapper {
    position: relative;
    box-sizing: border-box;
    padding: 10px 0;
    border: solid 1px var(--card-border-color);
    border-radius: 8px;

    margin: 5px 10px;
}

.card-content-wrapper.with-button {
    padding-bottom: 0;
}

.card-content-wrapper.with-button > .button.inline-button {
    margin: 0;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
}

.card-content-wrapper > .button.inline-button {
    padding: 15px 8px;
    font-size: 1rem;
    font-weight: 800;
}

.card-content-wrapper > .button.inline-button p {
    margin: 0;
}

@media screen and (min-width: 1000px) {

}