@media screen and ( min-width: 1000px ) {
    .head-to-head-wrapper {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 20px;
    }

    .head-to-head-wrapper .head-to-head-header {
        grid-column: 1/2;
    }

    .head-to-head-wrapper .head-to-head-matches {
        grid-column: 2/3;
        padding-top: 65px;
    }
}