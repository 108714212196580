.match-top-scorers .player-stats-table h4 {
    font-size: .9rem;
}

@media screen and ( min-width: 1000px ) {
    .match-top-scorers {
        display: grid;
        grid-template-columns: 1fr 1fr;

        gap: 20px;
    }

    .match-top-scorers h4 {
        grid-column: 1/3;
    }

    .match-top-scorers .player-stats-table:first-child {
        grid-column: 1/2;
    }

    .match-top-scorers .player-stats-table:last-child {
        grid-column: 2/3;
    }

    .match-top-scorers .player-stats-table a {
        border-bottom: solid 1px var(--card-border-color);
    }
}