.match-average-goals .average-goals-scoreline {
    display: grid;
    grid-template-columns: 1fr 1fr;
}

.match-average-goals .average-goals-scoreline > * {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.match-average-goals .average-goals-scoreline .crest {
    margin: 0 auto;
    width: 28px;
    height: 28px;
}

.match-average-goals .average-goals-scoreline p {
    font-weight: 500;
    font-size: 1.3rem;
    margin-bottom: 0;
}