.scrollable-column-chart {
    position: relative;
    width: 100%;
}

.scrollable-column-chart .selected-value {
    position: relative;
    width: 100%;
    padding: 10px 10px 15px 10px;
    box-sizing: border-box;
}

.scrollable-column-chart .selected-value p {
    margin: 0;
    text-align: center;

    color: var(--text-light);
    font-weight: 500;
    font-size: .9rem;
}

.scrollable-column-chart .columns {
    display: grid;
    grid-template-columns: 24px auto;
}

.scrollable-column-chart .columns .legend {
    display: grid;
    grid-template-rows: 9fr 1fr;
    gap: 5px;
}

.scrollable-column-chart .columns .legend .legend-values {
    position: relative;
    height: 100%;
}

.scrollable-column-chart .columns .legend .legend-values p {
    position: absolute;
    left: 0;

    font-size: .9rem;
    font-weight: 500;
    color: var(--text-light);

    margin: 0;
}

.scrollable-column-chart .columns .legend .legend-values p.v-100-pct {
    top: 0;
}

.scrollable-column-chart .columns .legend .legend-values p.v-75-pct {
    top: 25%;
    transform: translateY(-55%);
}

.scrollable-column-chart .columns .legend .legend-values p.v-50-pct {
    top: 50%;
    transform: translateY(-55%);
}

.scrollable-column-chart .columns .legend .legend-values p.v-25-pct {
    top: 75%;
    transform: translateY(-55%);
}

.scrollable-column-chart .columns .legend .legend-values p.v-0-pct {
    top: 100%;
    transform: translateY(-100%);
}

.scrollable-column-chart .columns .legend .legend-values {
    border-right: solid 1px var(--card-border-color);
}

.scrollable-column-chart .columns .column-bars {
    position: relative;
    overflow: hidden;
}

.scrollable-column-chart .columns .column-bars .column-bars-scrollable {
    position: relative;
    display: flex;
    flex-direction: row;
    gap: 10px;
    justify-content: flex-start;

    height: 200px;

    overflow-x: scroll;

    padding-left: 10px;
}

.scrollable-column-chart .columns .column-bars .ruler {
    position: absolute;
    left: 0;
    height: 1px;
    width: 100%;
    background-color: var(--card-border-color);
    z-index: 1;
}

.scrollable-column-chart .columns .column-bars .column-wrapper {
    display: grid;
    grid-template-rows: 9fr 1fr;
    gap: 5px;

    min-width: 40px;

    z-index: 2;
}

.scrollable-column-chart .columns .column-bars .column-wrapper .ruler {
    position: absolute;
    width: 100%;
    background-color: var(--card-border-color);
    height: 1px;
    z-index: 1;
    left: 0;
}

.scrollable-column-chart .columns .column-bars .labels .column-label p {
    margin: 0;
    font-size: .9rem;
    font-weight: 500;
    color: var(--text-label);
}

.scrollable-column-chart .columns .column-bars .column-value {
    display: flex;
    align-items: flex-end;
    justify-content: center;
    width: 100%;
}

.scrollable-column-chart .columns .column-value .column-bar {
    position: relative;
    background-color: var(--primary);
    border-radius: 4px 4px 0 0;
    width: 90%;
    animation: form-bar-appear .4s cubic-bezier(0.34, 1.56, 0.64, 1);
    animation-fill-mode: backwards;
    transform-origin: bottom center;

    cursor: pointer;

    transition: background-color .25s ease-in-out;
}

.scrollable-column-chart .columns .column-value .column-bar:hover {
    background-color: var(--secondary);
}

.scrollable-column-chart .columns .column-label {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: center;
}

.scrollable-column-chart .columns .column-label p {
    margin: 0;
    font-size: .9rem;
    font-weight: 500;
    color: var(--text-label);
}

