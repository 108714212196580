.save-picker .pinned-saves > div:first-child {
    border-radius: 10px 10px 0 0;
}

.save-picker .pinned-saves > div:last-child {
    border-radius: 0 0 10px 10px;
    border: none;
}

.save-picker .pinned-saves > div:first-child:last-child {
    border-radius: 10px;
}