.league-overview-screen .leagues-container .league {
    transition: background-color .2s ease-in-out;
    border-radius: 10px;
}

.league-overview-screen .leagues-container .league p {
    margin: 0;
    padding: 12px 0;
}

.league-overview-screen .leagues-container .league:hover {
    background-color: var(--card-hover-background-color);
}