.input-select {
    user-select: none;
    cursor: pointer;
}

.input-select .options-wrapper .option {
    transition: background-color .2s ease-in-out;
}

.input-select .options-wrapper .option.category {
    cursor: default;
}

.input-select .options-wrapper .option:not(.category):hover {
    background-color: var(--card-hover-background-color);
}

.input-field label {
    cursor: text;
}

.input-toggle label, .input-toggle input{
    cursor: pointer;
}

.input-toggle label {
    user-select: none;
}

.full-screen-select .option.default {
    position: relative;
    width: 100%;

    background-color: var(--select-options-background);

    padding: 20px 10px;
    box-sizing: border-box;

    border-bottom: solid 1px var(--card-border-color);

    margin-bottom: 30px;

    cursor: pointer;
}

.full-screen-select .option.default.with-icon {
    display: grid;
    grid-template-columns: 44px auto;
    align-items: center;
}

.full-screen-select .option.with-icon .fact-icon,
.full-screen-select-sheet .option.with-icon .fact-icon {
    display: flex;
}

.full-screen-select .option.with-icon .fact-icon img,
.full-screen-select-sheet .option.with-icon .fact-icon img {
    margin-bottom: unset;
}

.full-screen-select .option.default p {
    margin: 0;
}

.full-screen-select .option.default.disabled svg:last-child {
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
}