@media screen and ( min-width: 1000px ) {
    .match-details {
        display: grid;
        grid-template-columns: 40% 1fr;
        grid-column-gap: 30px;
    }

    .match-details > .match-header-wrapper {
        grid-column: 1/2;
    }

    .match-details > .match-facts {
        grid-column: 2/3;
    }

    .match-details > .match-data {
        grid-column: 1/2;
        grid-row: 1/2;
    }
}