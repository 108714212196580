button {
    cursor: pointer;
}

@media screen and (min-width: 1000px) {
    button {
        transition: transform .1s ease-in-out;
    }

    button:hover {
        filter: brightness(1.1);
    }

    button:active {
        transform: scale(0.95);
    }

    button[type=disabled] {
        cursor: not-allowed;
    }

    button[type=disabled]:hover {
        filter: none;
    }
}