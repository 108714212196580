.player-team-stat {
    display: grid;
    grid-template-columns: 30px auto 50px;
    align-items: center;
    gap: 7px;
}

.player-team-stat .crest {
    width: 30px;
    height: 30px;
}

.player-team-stat .value {
    font-weight: 600;
}