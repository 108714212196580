@media screen and (min-width: 1000px) {
    .team-best-players .team-stats-wrapper {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        gap: 20px;

        justify-content: center;
    }

    .team-best-players .team-stats-wrapper .player-stats-table {
        width: calc(50% - 20px);
    }
}