.tab-component {
    margin: 0 10px 15px 10px;
    background-color: var(--card-background-color);
    border-radius: 20px;
    padding: 10px 0;
    box-shadow: var(--card-box-shadow);
}

@media screen and (min-width: 1000px) {
    .overview-screen .content-wrapper {
        display: grid;
        grid-template-columns: 1fr 40%;
    }

    .overview-screen .content-wrapper .tab-component {
        grid-column: 1 / 2;
        grid-row: 1 / 2;
    }

    .overview-screen .content-wrapper .overview-stats {
        grid-column: 2 / 3;
        grid-row: 1 / 2;
    }

    .overview-screen .content-wrapper .overview-stats {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
    }

    .overview-screen .content-wrapper .overview-stats .horizontal-scrollable {
        white-space: unset;
    }

    .overview-screen .overview-stats .stat-card {
        width: calc(50% - 20px);
        margin: 10px;
    }
}