.match-facts.timeline {
    position: relative;
    width: 100%;

    flex-direction: column;

    background-color: unset;
    margin: unset;
    padding: unset;
    box-shadow: unset;

}

.match-facts.timeline::before {
    content: '';
    position: absolute;

    top: 0;
    left: 50%;

    transform: translateX(-50%);

    background-color: var(--card-border-color);

    height: 100%;
    width: 4px;

    border-radius: 4px;
}

.match-facts.timeline .fact {
    position: relative;

    display: flex;
    flex-direction: column;

    box-shadow: var(--card-box-shadow);
    border-radius: 20px;

    background-color: var(--card-background-color);

    margin: var(--card-margin);
    margin-bottom: 30px;

    overflow: hidden;
}

.match-facts.timeline .fact .fact-header {
    position: relative;
    width: 100%;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    box-sizing: border-box;
}

.match-facts.timeline .fact .fact-header .fact-header-content {
    padding: 20px 10px;
    box-sizing: border-box;

    display: grid;
    grid-template-columns: 32px 1fr;
}

.match-facts.timeline .fact .fact-header .fact-header-content .type-and-time {
    display: grid;
    grid-template-columns: 1fr 32px;

    font-weight: bold;
    font-size: 1rem;
}

.match-facts.timeline .fact .fact-header.goal .fact-header-content,
.match-facts.timeline .fact .fact-header.owngoal .fact-header-content,
.match-facts.timeline .fact .fact-header.penalty_goal .fact-header-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 5px;

    padding: 15px 10px;
    border: none;

    font-weight: bold;
}

.match-facts.timeline .fact .fact-header.goal .fact-icon,
.match-facts.timeline .fact .fact-header.owngoal .fact-icon,
.match-facts.timeline .fact .fact-header.penalty_goal .fact-icon {
    height: 24px;
}

.match-facts.timeline .fact .fact-header.goal .fact-icon::after,
.match-facts.timeline .fact .fact-header.owngoal .fact-icon::after,
.match-facts.timeline .fact .fact-header.penalty_goal .fact-icon::after {
    content: '';
    position: absolute;
    z-index: -1;

    width: 24px;
    height: 24px;
    border-radius: 500px;

    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    background-color: var(--card-background-color);
}

.match-facts.timeline .fact .fact-header.goal .fact-header-content .type-and-time,
.match-facts.timeline .fact .fact-header.owngoal .fact-header-content .type-and-time,
.match-facts.timeline .fact .fact-header.penalty_goal .fact-header-content .type-and-time {
    display: flex;
    flex-direction: row;
    gap: 4px;
}

.match-facts.timeline .fact .fact-header .fact-header-footer {
    position: relative;
    width: 100%;

    background-color: rgba(0, 0, 0, 0.25);
}

.match-facts.timeline .fact .fact-header .fact-header-footer .score {
    font-weight: bold;
    font-size: 1rem;
    text-align: center;

    padding: 10px 15px;
    box-sizing: border-box;
}

.match-facts.timeline .fact .fact-header .fact-header-footer .score span:not(.highlighted) {
    opacity: .5;
}

.match-facts.timeline .fact .content {
    position: relative;
    width: 100%;

    display: grid;
    grid-template-columns: auto 24px;
    grid-template-rows: auto auto;
    gap: 4px;

    text-align: left;

    padding: 15px;
    border-top: none;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
    box-sizing: border-box;
}

.match-facts.timeline .fact .content .details {
    text-align: left;
    font-weight: 500;
    font-size: 1rem;

    grid-column-start: 1;
    grid-column-end: 1;
    grid-row-start: 1;
    grid-row-end: 1;
}

.match-facts.timeline .fact .content .extra-details {
    color: var(--text-light);
    font-size: .9rem;

    grid-column-start: 1;
    grid-column-end: 1;
    grid-row-start: 2;
    grid-row-end: 2;
}

.match-facts.timeline .fact .content .crest {
    grid-column-start: 2;
    grid-column-end: 2;
    grid-row-start: 1;
    grid-row-end: 3;

    width: 24px;
}

.match-facts.timeline .fact .fact-icon {
    position: relative;
    z-index: 1;
    border: none;
}